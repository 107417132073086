import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Card,
    CardActionArea,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    Skeleton,
    TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { SaveOutlined } from "@mui/icons-material";
import { useAlert } from "../../services/alert";
import { useApiPrivate } from "../../api/api-private";
import ContentLayout from "../../layouts/ContentLayout";
import Translations from "../elements/Translations";
import Map from "../maps/Map";
import { Location } from "../../models/location";
import { Poi, PoiDetail } from "../../models/poi";
import { isValidUrl } from "../../helpers/utils";
import Dropzone from "react-dropzone";

const PoiForm = ({
    type,
    location,
    poi,
}: {
    type: "new" | "edit";
    location: Location;
    poi?: Poi;
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const alert = useAlert();
    const apiPrivate = useApiPrivate();

    const [tab, setTab] = useState<number>(0);
    const [nameEn, setNameEn] = useState<PoiDetail["name"]>(
        poi && poi.poidetails
            ? poi.poidetails.find((detail) => detail.language === "ENGLISH")
                  ?.name ?? ""
            : ""
    );
    const [nameHr, setNameHr] = useState<PoiDetail["name"]>(
        poi && poi.poidetails
            ? poi.poidetails.find((detail) => detail.language === "CROATIAN")
                  ?.name ?? ""
            : ""
    );
    const [descriptionEn, setDescriptionEn] = useState<
        PoiDetail["description"]
    >(
        poi && poi.poidetails
            ? poi.poidetails.find((detail) => detail.language === "ENGLISH")
                  ?.description ?? ""
            : ""
    );
    const [descriptionHr, setDescriptionHr] = useState<
        PoiDetail["description"]
    >(
        poi && poi.poidetails
            ? poi.poidetails.find((detail) => detail.language === "CROATIAN")
                  ?.description ?? ""
            : ""
    );
    const [visitLinkEn, setVisitLinkEn] = useState<PoiDetail["url"]>(
        poi && poi.poidetails
            ? poi.poidetails.find((detail) => detail.language === "ENGLISH")
                  ?.url ?? ""
            : ""
    );
    const [visitLinkHr, setVisitLinkHr] = useState<PoiDetail["url"]>(
        poi && poi.poidetails
            ? poi.poidetails.find((detail) => detail.language === "CROATIAN")
                  ?.url ?? ""
            : ""
    );
    const [buyLinkEn, setBuyLinkEn] = useState<PoiDetail["buyButton"]>(
        poi && poi.poidetails
            ? poi.poidetails.find((detail) => detail.language === "ENGLISH")
                  ?.buyButton ?? ""
            : ""
    );
    const [buyLinkHr, setBuyLinkHr] = useState<PoiDetail["buyButton"]>(
        poi && poi.poidetails
            ? poi.poidetails.find((detail) => detail.language === "CROATIAN")
                  ?.buyButton ?? ""
            : ""
    );
    const [latitude, setLatitude] = useState<Poi["latitude"]>(
        poi ? poi.latitude : location.latitude
    );
    const [longitude, setLongitude] = useState<Poi["longitude"]>(
        poi ? poi.longitude : location.longitude
    );
    const [imageUrl] = useState<Poi["imageUrl"]>(poi ? poi.imageUrl : "");

    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [newImage, setNewImage] = useState<File | null>(null);

    const handlePoiSave = () => {
        setIsLoading(true);

        if (type === "new") {
            createPoi();
        } else if (type === "edit") {
            updatePoi();
        }
    };

    const createPoi = () => {
        if (!location) return;

        apiPrivate
            .createPoi(location.id, latitude, longitude, mapPoiDetails())
            .then((response) => {
                console.log("createPoi", response);

                handlePoiImage(true, response.data as Poi);
            })
            .catch((error) => {
                console.error("createPoi", error);

                setIsLoading(false);
                alert.show(t("errors.generic"), "error", 5000);
            });
    };

    const updatePoi = () => {
        if (!location || !poi) return;

        apiPrivate
            .updatePoi(
                location.id,
                poi.id,
                latitude,
                longitude,
                poi.imageUrl,
                mapPoiDetails()
            )
            .then((response) => {
                console.log("updatePoi", response);

                handlePoiImage(false);
            })
            .catch((error) => {
                console.error("updatePoi", error);

                setIsLoading(false);
                alert.show(t("errors.generic"), "error", 5000);
            });
    };

    const handlePoiImage = (
        isCreate = true,
        newPoi: Poi | null = poi ?? null
    ) => {
        if (!location || !newPoi || !newImage) {
            setIsLoading(false);
            alert.show(
                isCreate
                    ? t("messages.poi-created")
                    : t("messages.poi-updated"),
                "success"
            );

            navigate(`/locations/${location.id}/pois`);
            return;
        }

        apiPrivate
            .uploadPoiImage(location.id, newPoi.id, newImage)
            .then((response) => {
                console.log("uploadPoiImage", response);

                alert.show(
                    isCreate
                        ? t("messages.poi-created")
                        : t("messages.poi-updated"),
                    "success"
                );

                navigate(`/locations/${location.id}/pois`);
            })
            .catch((error) => {
                console.error("uploadPoiImage", error);

                alert.show(t("errors.generic"), "error", 5000);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const mapPoiDetails = () => {
        return [
            {
                language: "CROATIAN",
                name: nameHr,
                description: descriptionHr,
                url: visitLinkHr,
                buyButton: buyLinkHr,
            },
            {
                language: "ENGLISH",
                name: nameEn,
                description: descriptionEn,
                url: visitLinkEn,
                buyButton: buyLinkEn,
            },
        ] as PoiDetail[];
    };

    useEffect(() => {
        setIsDisabled(
            nameEn === "" ||
                nameHr === "" ||
                descriptionEn === "" ||
                descriptionHr === "" ||
                (visitLinkEn !== "" && !isValidUrl(visitLinkEn)) ||
                (visitLinkHr !== "" && !isValidUrl(visitLinkHr)) ||
                (buyLinkEn !== "" && !isValidUrl(buyLinkEn)) ||
                (buyLinkHr !== "" && !isValidUrl(buyLinkHr))
        );
    }, [
        nameEn,
        nameHr,
        descriptionEn,
        descriptionHr,
        visitLinkEn,
        visitLinkHr,
        buyLinkEn,
        buyLinkHr,
    ]);

    return (
        <Fragment>
            <ContentLayout
                title={t("titles.information") ?? ""}
                caption={t("captions.required-field") ?? ""}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Translations tab={tab} setTab={setTab} />

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="dense">
                                    <TextField
                                        required
                                        label={t("inputs.name")}
                                        value={tab === 0 ? nameEn : nameHr}
                                        onChange={(event) =>
                                            tab === 0
                                                ? setNameEn(event.target.value)
                                                : setNameHr(event.target.value)
                                        }
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider light />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth margin="dense">
                                    <TextField
                                        required
                                        multiline
                                        minRows={3}
                                        maxRows={Infinity}
                                        label={t("inputs.short-description")}
                                        value={
                                            tab === 0
                                                ? descriptionEn
                                                : descriptionHr
                                        }
                                        onChange={(event) =>
                                            tab === 0
                                                ? setDescriptionEn(
                                                      event.target.value
                                                  )
                                                : setDescriptionHr(
                                                      event.target.value
                                                  )
                                        }
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth margin="dense">
                                    <TextField
                                        label={t("inputs.visit-link")}
                                        helperText={t("captions.link-url")}
                                        value={
                                            tab === 0
                                                ? visitLinkEn
                                                : visitLinkHr
                                        }
                                        onChange={(event) =>
                                            tab === 0
                                                ? setVisitLinkEn(
                                                      event.target.value
                                                  )
                                                : setVisitLinkHr(
                                                      event.target.value
                                                  )
                                        }
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth margin="dense">
                                    <TextField
                                        label={t("inputs.buy-link")}
                                        helperText={t("captions.link-url")}
                                        value={
                                            tab === 0 ? buyLinkEn : buyLinkHr
                                        }
                                        onChange={(event) =>
                                            tab === 0
                                                ? setBuyLinkEn(
                                                      event.target.value
                                                  )
                                                : setBuyLinkHr(
                                                      event.target.value
                                                  )
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ContentLayout>

            <ContentLayout
                title={t("titles.map") ?? ""}
                caption={t("captions.drag-poi") ?? ""}
                hasMargin={true}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Map
                            zoom={location.initialZoom}
                            markers={[
                                {
                                    id: 0,
                                    latitude: latitude,
                                    longitude: longitude,
                                    imageUrl: null,
                                    ctime: new Date().toLocaleDateString(),
                                    poidetails: null,
                                },
                            ]}
                            setMarkers={(markers) => {
                                setLatitude(markers[0].latitude);
                                setLongitude(markers[0].longitude);
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth margin="dense">
                            <TextField
                                disabled
                                label={t("inputs.latitude")}
                                type="number"
                                inputProps={{
                                    step: "0.000001",
                                }}
                                value={latitude}
                                onChange={(event) =>
                                    setLatitude(parseFloat(event.target.value))
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth margin="dense">
                            <TextField
                                disabled
                                label={t("inputs.longitude")}
                                type="number"
                                inputProps={{
                                    step: "0.000001",
                                }}
                                value={longitude}
                                onChange={(event) =>
                                    setLongitude(parseFloat(event.target.value))
                                }
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </ContentLayout>

            <ContentLayout title={t("titles.image") ?? ""} hasMargin={true}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Dropzone
                                maxFiles={1}
                                maxSize={50000000}
                                onDrop={(files) => setNewImage(files[0])}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <Card
                                        variant="outlined"
                                        {...getRootProps()}
                                    >
                                        <CardActionArea>
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    height: "240px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    padding: 2,
                                                }}
                                            >
                                                {newImage ? (
                                                    <img
                                                        src={URL.createObjectURL(
                                                            newImage
                                                        )}
                                                        width="100%"
                                                        height="100%"
                                                        alt="POI"
                                                        style={{
                                                            objectFit:
                                                                "contain",
                                                        }}
                                                    />
                                                ) : imageUrl ? (
                                                    <img
                                                        src={imageUrl}
                                                        width="100%"
                                                        height="100%"
                                                        alt="POI"
                                                        style={{
                                                            objectFit:
                                                                "contain",
                                                        }}
                                                    />
                                                ) : (
                                                    <Skeleton
                                                        variant="rectangular"
                                                        width={300}
                                                        height={200}
                                                    />
                                                )}

                                                <input
                                                    {...getInputProps({
                                                        accept: "image/*",
                                                    })}
                                                />
                                            </Box>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Dropzone>

                            <FormHelperText
                                sx={{
                                    marginX: 0,
                                }}
                            >
                                {t("inputs.upload")}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            </ContentLayout>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 4,
                }}
            >
                <LoadingButton
                    loading={isLoading}
                    disabled={isDisabled}
                    variant="contained"
                    disableElevation
                    onClick={handlePoiSave}
                    startIcon={<SaveOutlined />}
                >
                    {t("buttons.save")}
                </LoadingButton>
            </Box>
        </Fragment>
    );
};

export default PoiForm;
