import { useEffect, useState } from "react";
import { createSingletonHook } from "../helpers/singleton";
import { Lang, Token } from "../models/prefs";

export const [usePrefs, PrefsProvider] = createSingletonHook(() => {
    const [lang, setLang] = useState<Lang>(
        localStorage.getItem("lang") === "hr" ? "hr" : "en",
    );

    const [token, setToken] = useState<Token | null>(
        localStorage.getItem("token") ?? null,
    );

    useEffect(() => {
        localStorage.setItem("lang", lang);
    }, [lang]);

    useEffect(() => {
        if (token) localStorage.setItem("token", token);
        else localStorage.removeItem("token");
    }, [token]);

    return {
        lang,
        setLang,
        token,
        setToken,
    };
});
