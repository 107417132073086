import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, FormControl, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import AuthLayout from "../../layouts/AuthLayout";
import { useAuth } from "../../services/auth";
import { useApiAuth } from "../../api/api-auth";
import { isValidEmail } from "../../helpers/utils";
import { User } from "../../models/user";

const ForgotPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const auth = useAuth();
    const apiAuth = useApiAuth();

    const [email, setEmail] = useState<User["email"]>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [isRequested, setIsRequested] = useState<boolean>(false);

    const handleForgotPassword = () => {
        if (isLoading || !email) return;

        setIsLoading(true);

        apiAuth
            .forgotPassword(email)
            .then((response) => {
                console.log("forgotPassword", response);
            })
            .catch((error) => {
                console.error("forgotPassword", error);
            })
            .finally(() => {
                setIsRequested(true);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        setIsDisabled(email === "" || !isValidEmail(email));
    }, [email]);

    useEffect(() => {
        if (auth.user) navigate("/");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.user]);

    return (
        <AuthLayout>
            <Typography variant="h1">{t("titles.forgot-password")}</Typography>

            <Typography variant="caption">
                {t("captions.forgot-password")}
            </Typography>

            <Box
                sx={{
                    marginTop: 6,
                }}
            >
                <FormControl fullWidth margin="normal">
                    <TextField
                        type="email"
                        label={t("inputs.email")}
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        disabled={isRequested}
                        helperText={
                            isRequested
                                ? t("messages.password-reset-send")
                                : " "
                        }
                    />
                </FormControl>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <LoadingButton
                        loading={isLoading}
                        disabled={isDisabled || isRequested}
                        variant="contained"
                        disableElevation
                        onClick={handleForgotPassword}
                    >
                        {isRequested ? t("buttons.sent") : t("buttons.send")}
                    </LoadingButton>
                </Box>
            </Box>
        </AuthLayout>
    );
};

export default ForgotPassword;
