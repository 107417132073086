import { Box, Container, Typography } from "@mui/material";

const Error404 = () => {
    return (
        <Container>
            <Box
                sx={{
                    marginTop: 4,
                }}
            >
                <Typography variant="h1">404</Typography>
            </Box>
        </Container>
    );
};

export default Error404;
