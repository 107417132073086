import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Button, Menu, MenuItem } from "@mui/material";
import { teal } from "@mui/material/colors";
import { PersonOutlineOutlined } from "@mui/icons-material";
import { useAuth } from "../../services/auth";

const Profile = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const auth = useAuth();

    const [anchor, setAnchor] = useState<HTMLElement | null>(null);

    return auth.user ? (
        <Box
            sx={{
                marginLeft: 2,
            }}
        >
            <Button
                onClick={(event) => setAnchor(event.currentTarget)}
                endIcon={
                    <Avatar
                        src={auth.user.imageUrl ?? undefined}
                        sx={{
                            width: 28,
                            height: 28,
                            background: teal["A400"],
                            color: teal[800],
                        }}
                    >
                        {!auth.user.imageUrl && (
                            <PersonOutlineOutlined fontSize="small" />
                        )}
                    </Avatar>
                }
                sx={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: "currentColor",
                }}
            >
                {auth.user.firstName ?? ""}
            </Button>

            <Menu
                anchorEl={anchor}
                open={Boolean(anchor)}
                onClose={() => setAnchor(null)}
            >
                <MenuItem onClick={() => navigate("/account")}>
                    {t("titles.account")}
                </MenuItem>

                <MenuItem onClick={() => auth.logOut()}>
                    {t("buttons.log-out")}
                </MenuItem>
            </Menu>
        </Box>
    ) : (
        <Fragment />
    );
};

export default Profile;
