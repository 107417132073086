import { Lang } from "../models/prefs";
import { Location } from "../models/location";
import { Poi } from "../models/poi";
import { Screen } from "../models/screen";

export const isStrongPassword = (password: string): boolean => {
    return /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(
        password
    );
};

export const isValidEmail = (email: string): boolean => {
    return /\S+@\S+\.\S+/.test(email);
};

export const isValidUrl = (url: string) => {
    try {
        return Boolean(new URL(url));
    } catch (event) {
        return false;
    }
};

export const getLocationNameLocalized = (location: Location, lang: Lang) => {
    return location.names && location.names.length > 0
        ? location.names.find(
              (name) =>
                  name.language === (lang === "en" ? "ENGLISH" : "CROATIAN")
          )?.name ?? "Unknown"
        : "Unknown";
};

export const getPoiLocalized = (poi: Poi, lang: Lang) => {
    return poi.poidetails && poi.poidetails.length > 0
        ? poi.poidetails.find(
              (details) =>
                  details.language === (lang === "en" ? "ENGLISH" : "CROATIAN")
          ) ?? null
        : null;
};

export const getPoiNameLocalized = (poi: Poi, lang: Lang) => {
    return poi.poidetails && poi.poidetails.length > 0
        ? poi.poidetails.find(
              (details) =>
                  details.language === (lang === "en" ? "ENGLISH" : "CROATIAN")
          )?.name ?? "Unknown"
        : "Unknown";
};

export const getPoiDescriptionLocalized = (poi: Poi, lang: Lang) => {
    return poi.poidetails && poi.poidetails.length > 0
        ? poi.poidetails.find(
              (details) =>
                  details.language === (lang === "en" ? "ENGLISH" : "CROATIAN")
          )?.description ?? null
        : null;
};

export const getPoiVisitLinkLocalized = (poi: Poi, lang: Lang) => {
    return poi.poidetails && poi.poidetails.length > 0
        ? poi.poidetails.find(
              (details) =>
                  details.language === (lang === "en" ? "ENGLISH" : "CROATIAN")
          )?.url ?? null
        : null;
};

export const getPoiBuyLinkLocalized = (poi: Poi, lang: Lang) => {
    return poi.poidetails && poi.poidetails.length > 0
        ? poi.poidetails.find(
              (details) =>
                  details.language === (lang === "en" ? "ENGLISH" : "CROATIAN")
          )?.buyButton ?? null
        : null;
};

export const getLandingPageDetailsLocalized = (screen: Screen, lang: Lang) => {
    return screen.landingPageDetails && screen.landingPageDetails.length > 0
        ? screen.landingPageDetails.find(
              (details) =>
                  details.language === (lang === "en" ? "ENGLISH" : "CROATIAN")
          ) ?? null
        : null;
};

export const isSearchInLocation = (location: Location, search: string) => {
    return (
        (location.names &&
            location.names.length > 0 &&
            location.names.find((name) =>
                name.name.toLowerCase().includes(search.toLowerCase())
            )) ||
        location.externalId.includes(search)
    );
};

export const isSearchInPoi = (poi: Poi, search: string) => {
    return (
        poi.poidetails &&
        poi.poidetails.length > 0 &&
        poi.poidetails.find((details) =>
            details.name.toLowerCase().includes(search.toLowerCase())
        )
    );
};

export const getMapZoom = (location: Location | null): number | undefined => {
    return location ? location.initialZoom : undefined;
};

export const getMapMarkers = (location: Location | null): Poi[] | undefined => {
    return location
        ? location.pois && location.pois.length > 0
            ? location.pois
            : [
                  {
                      id: 0,
                      latitude: location.latitude,
                      longitude: location.longitude,
                      imageUrl: null,
                      ctime: new Date().toLocaleDateString(),
                      poidetails: null,
                  },
              ]
        : undefined;
};
