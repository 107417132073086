import {
    Dispatch,
    Fragment,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
    GoogleMap,
    HeatmapLayer,
    InfoWindow,
    Marker,
    useJsApiLoader,
} from "@react-google-maps/api";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { usePrefs } from "../../services/prefs";
import {
    getPoiBuyLinkLocalized,
    getPoiDescriptionLocalized,
    getPoiNameLocalized,
    getPoiVisitLinkLocalized,
} from "../../helpers/utils";
import {
    DEFAULT_LATITUDE,
    DEFAULT_LONGITUDE,
    DEFAULT_ZOOM,
} from "../../helpers/constants";
import { HeatMap } from "../../models/location";
import { Poi } from "../../models/poi";

const mapLibraries: ["visualization", "places"] = ["visualization", "places"];

const Map = ({
    zoom = DEFAULT_ZOOM,
    setZoom,
    markers,
    setMarkers,
    points,
    interval,
}: {
    zoom?: number;
    setZoom?: Dispatch<SetStateAction<number>>;
    markers?: Poi[];
    setMarkers?: (markers: Poi[]) => void;
    points?: HeatMap[][];
    interval?: number;
}) => {
    const { t } = useTranslation();
    const prefs = usePrefs();

    const { isLoaded } = useJsApiLoader({
        id: "google-map",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? "",
        libraries: mapLibraries,
    });

    const [key, setKey] = useState<number>(+new Date());
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [isMarkerOpen, setIsMarkerOpen] = useState<boolean>(false);
    const [markerModal, setMarkerModal] = useState<{
        index: number;
        marker: Poi;
    } | null>(null);

    const onLoad = useCallback((map: google.maps.Map) => {
        setMap(map);
    }, []);

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    const onZoom = () => {
        if (!map || !setZoom) return;

        setZoom(map.getZoom() ?? zoom);
    };

    const onMarkerMove = (marker: any) => {
        if (!setMarkers) return;

        setMarkers([
            {
                id: 0,
                latitude: parseFloat(marker.latLng.lat().toFixed(6)),
                longitude: parseFloat(marker.latLng.lng().toFixed(6)),
                imageUrl: null,
                ctime: new Date().toLocaleDateString(),
                poidetails: null,
            },
        ]);
    };

    const onMarkerOpen = (marker: Poi, index: number) => {
        setMarkerModal({ index, marker });
        setIsMarkerOpen(true);
    };

    const onMarkerClose = () => {
        setIsMarkerOpen(false);
        setMarkerModal(null);
    };

    useEffect(() => {
        setKey(+new Date());
    }, [zoom]);

    return isLoaded ? (
        <GoogleMap
            key={key}
            mapContainerStyle={{
                width: "100%",
                height: "450px",
            }}
            center={
                markers
                    ? {
                          lat: markers[0].latitude,
                          lng: markers[0].longitude,
                      }
                    : {
                          lat: DEFAULT_LATITUDE,
                          lng: DEFAULT_LONGITUDE,
                      }
            }
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onZoomChanged={onZoom}
            onClick={onMarkerClose}
            options={{
                fullscreenControl: false,
                streetViewControl: false,
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels",
                        stylers: [{ visibility: "off" }],
                    },
                ],
            }}
        >
            {markers && (
                <Fragment>
                    {setMarkers ? (
                        <Marker
                            position={{
                                lat: markers[0].latitude,
                                lng: markers[0].longitude,
                            }}
                            draggable={true}
                            onDragEnd={onMarkerMove}
                        />
                    ) : (
                        markers.map((marker, index) => {
                            const visitLink = getPoiVisitLinkLocalized(
                                marker,
                                prefs.lang
                            );
                            const buyLink = getPoiBuyLinkLocalized(
                                marker,
                                prefs.lang
                            );

                            return (
                                <Marker
                                    key={marker.id}
                                    position={{
                                        lat: marker.latitude,
                                        lng: marker.longitude,
                                    }}
                                    onClick={() => onMarkerOpen(marker, index)}
                                >
                                    {isMarkerOpen &&
                                        markerModal &&
                                        markerModal.index === index && (
                                            <InfoWindow
                                                onCloseClick={onMarkerClose}
                                            >
                                                <Fragment>
                                                    <Box
                                                        sx={{
                                                            padding: 0.5,
                                                        }}
                                                    >
                                                        {marker.imageUrl && (
                                                            <img
                                                                src={
                                                                    marker.imageUrl
                                                                }
                                                                alt={getPoiNameLocalized(
                                                                    markerModal.marker,
                                                                    prefs.lang
                                                                )}
                                                                style={{
                                                                    width: "224px",
                                                                    height: "126px",
                                                                    objectFit:
                                                                        "cover",
                                                                    borderRadius:
                                                                        "4px",
                                                                    marginBottom:
                                                                        "8px",
                                                                }}
                                                            />
                                                        )}

                                                        <Box
                                                            sx={{
                                                                maxWidth:
                                                                    "224px",
                                                                overflow:
                                                                    "hidden",
                                                            }}
                                                        >
                                                            <Typography>
                                                                {getPoiNameLocalized(
                                                                    markerModal.marker,
                                                                    prefs.lang
                                                                )}
                                                            </Typography>

                                                            <Typography
                                                                component="p"
                                                                variant="caption"
                                                                noWrap={true}
                                                            >
                                                                {getPoiDescriptionLocalized(
                                                                    markerModal.marker,
                                                                    prefs.lang
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    </Box>

                                                    {(visitLink || buyLink) && (
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "space-between",
                                                            }}
                                                        >
                                                            {visitLink && (
                                                                <Button
                                                                    color="info"
                                                                    size="small"
                                                                    onClick={() =>
                                                                        window.open(
                                                                            visitLink,
                                                                            "_blank"
                                                                        )
                                                                    }
                                                                >
                                                                    {t(
                                                                        "buttons.visit"
                                                                    )}
                                                                </Button>
                                                            )}

                                                            {buyLink && (
                                                                <Button
                                                                    size="small"
                                                                    sx={{
                                                                        marginLeft: 2,
                                                                    }}
                                                                    onClick={() =>
                                                                        window.open(
                                                                            buyLink,
                                                                            "_blank"
                                                                        )
                                                                    }
                                                                >
                                                                    {t(
                                                                        "buttons.buy"
                                                                    )}
                                                                </Button>
                                                            )}
                                                        </Box>
                                                    )}
                                                </Fragment>
                                            </InfoWindow>
                                        )}
                                </Marker>
                            );
                        })
                    )}
                </Fragment>
            )}

            {points && points.length > 0 && typeof interval !== "undefined" && (
                <HeatmapLayer
                    data={points[interval].map(
                        (point: HeatMap) =>
                            new window.google.maps.LatLng(
                                parseFloat(point.latitude),
                                parseFloat(point.longitude)
                            )
                    )}
                />
            )}
        </GoogleMap>
    ) : (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100px",
            }}
        >
            <CircularProgress />
        </Box>
    );
};

export default Map;
