import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Box, Container, Typography } from "@mui/material";
import flagEu from "../../images/flag-eu.svg";
import Alert from "./Alert";

const Footer = ({ isWelcome = false }: { isWelcome?: boolean }) => {
    const { t } = useTranslation();

    const footerProject = () => {
        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <img
                    src={flagEu}
                    alt="EU"
                    style={{
                        width: "60px",
                        height: "40px",
                        objectFit: "contain",
                        marginRight: 2,
                    }}
                />

                <Box
                    sx={{
                        marginLeft: 1,
                        lineHeight: 1,
                    }}
                >
                    <Typography
                        variant="caption"
                        lineHeight="1"
                        sx={{
                            color: "inherit",
                        }}
                    >
                        {t("messages.eu-project")}
                    </Typography>
                </Box>
            </Box>
        );
    };

    return isWelcome ? (
        <Container
            sx={{
                marginTop: 8,
                marginBottom: 2,
            }}
        >
            {footerProject()}

            <Alert />
        </Container>
    ) : (
        <Fragment>
            {footerProject()}

            <Alert />
        </Fragment>
    );
};

export default Footer;
