import { Route } from "../models/route";
import Welcome from "../pages/Welcome";

export const publicRoutes: Route[] = [
    {
        path: "/welcome/:token",
        element: Welcome,
        roles: [],
    },
    {
        path: "/welcome/:token/success",
        element: Welcome,
        roles: [],
    },
];
