import { useTranslation } from "react-i18next";
import {
    Button,
    Table as MTable,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

const Table = ({
    type,
    columns,
    rows,
    onView,
    onEdit,
    onDelete,
}: {
    type: "locations" | "pois" | "users" | "screens";
    columns: string[];
    rows: string[][];
    onView?: (id: string) => void;
    onEdit?: (id: string) => void;
    onDelete?: (id: string) => void;
}) => {
    const { t } = useTranslation();

    return (
        <MTable size="small">
            <TableHead
                sx={{
                    position: "sticky",
                    top: "172px",
                    background: "white",
                    zIndex: 1,
                }}
            >
                <TableRow>
                    {columns.map((column) => (
                        <TableCell key={column}>{column}</TableCell>
                    ))}

                    {(onView || onEdit || onEdit) && (
                        <TableCell
                            sx={{
                                paddingLeft: 3,
                            }}
                        >
                            {t("columns.actions")}
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>

            <TableBody>
                {rows.length > 0 ? (
                    rows.map((row) => {
                        const id = row[0];

                        return (
                            <TableRow key={id} hover>
                                {row.map((data, index) =>
                                    index > 0 ? (
                                        <TableCell key={index}>
                                            {data}
                                        </TableCell>
                                    ) : null
                                )}

                                {(onView || onEdit || onDelete) && (
                                    <TableCell>
                                        {onView && (
                                            <Button
                                                onClick={() => onView(row[0])}
                                                color="info"
                                                sx={{
                                                    marginRight: 1,
                                                }}
                                            >
                                                {t(
                                                    type === "locations"
                                                        ? "buttons.pois"
                                                        : type === "screens"
                                                        ? "buttons.preview"
                                                        : "buttons.view"
                                                )}
                                            </Button>
                                        )}

                                        {onEdit && (
                                            <Button
                                                onClick={() => onEdit(row[0])}
                                                sx={{
                                                    marginRight: 1,
                                                }}
                                            >
                                                {t("buttons.edit")}
                                            </Button>
                                        )}

                                        {onDelete && (
                                            <Button
                                                onClick={() => onDelete(row[0])}
                                                color="error"
                                                sx={{
                                                    marginRight: 1,
                                                }}
                                            >
                                                {t("buttons.delete")}
                                            </Button>
                                        )}
                                    </TableCell>
                                )}
                            </TableRow>
                        );
                    })
                ) : (
                    <TableRow>
                        <TableCell colSpan={columns.length + 1}>
                            <Typography
                                variant="caption"
                                component="p"
                                sx={{
                                    paddingY: 1,
                                }}
                            >
                                {t("columns.no-results")}
                            </Typography>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </MTable>
    );
};

export default Table;
