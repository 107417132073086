import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { format } from "date-fns";
import en from "date-fns/locale/en-GB";
import hr from "date-fns/locale/hr";
import { Box, Grid, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { FileDownloadOutlined } from "@mui/icons-material";
import { usePrefs } from "../../services/prefs";
import ContentLayout from "../../layouts/ContentLayout";
import Map from "./Map";
import { getMapMarkers, getMapZoom } from "../../helpers/utils";
import { HeatMap, Location } from "../../models/location";

const ReportMap = ({
    type,
    location,
    currentInterval,
    heatMapData,
    groupedHeatMapData,
    isLoading,
    isReportLoading,
    handleReportDownload,
}: {
    type: "reports" | "dashboard";
    location: Location | null;
    currentInterval: number;
    heatMapData: HeatMap[];
    groupedHeatMapData: HeatMap[][];
    isLoading: boolean;
    isReportLoading?: boolean;
    handleReportDownload?: () => void;
}) => {
    const { t } = useTranslation();
    const prefs = usePrefs();

    const uniqueDevices = () => {
        const uniqueDevicesByMac = _.uniqBy(heatMapData, "mac");
        return uniqueDevicesByMac.length;
    };

    return (
        <ContentLayout hasMargin={true}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Map
                        zoom={getMapZoom(location)}
                        markers={getMapMarkers(location)}
                        points={groupedHeatMapData}
                        interval={currentInterval}
                    />
                </Grid>

                {heatMapData && heatMapData.length > 0 && (
                    <Fragment>
                        <Grid item xs={10}>
                            <Typography component="p" variant="caption">
                                {`${
                                    groupedHeatMapData[currentInterval].length
                                } ${t("messages.current-points")} (${format(
                                    new Date(
                                        groupedHeatMapData[
                                            currentInterval
                                        ][0].time.slice(0, -1)
                                    ),
                                    "Pp",
                                    { locale: prefs.lang === "hr" ? hr : en }
                                )} - ${format(
                                    new Date(
                                        groupedHeatMapData[currentInterval][
                                            groupedHeatMapData[currentInterval]
                                                .length - 1
                                        ].time.slice(0, -1)
                                    ),
                                    "Pp",
                                    { locale: prefs.lang === "hr" ? hr : en }
                                )})`}
                            </Typography>

                            <Typography component="p" variant="caption">
                                {`${uniqueDevices()} ${t(
                                    "messages.current-devices"
                                )}`}
                            </Typography>
                        </Grid>

                        {type === "reports" && (
                            <Grid item xs={2}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                        height: "100%",
                                    }}
                                >
                                    <LoadingButton
                                        disabled={isLoading}
                                        variant="contained"
                                        disableElevation
                                        onClick={handleReportDownload}
                                        loading={isReportLoading}
                                        startIcon={<FileDownloadOutlined />}
                                    >
                                        {t("buttons.download")}
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        )}
                    </Fragment>
                )}
            </Grid>
        </ContentLayout>
    );
};

export default ReportMap;
