import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import {
    Autocomplete,
    Avatar,
    Box,
    Card,
    CardActionArea,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { teal } from "@mui/material/colors";
import {
    AddPhotoAlternateOutlined,
    PersonOutlineOutlined,
    SaveOutlined,
    Visibility,
    VisibilityOff,
} from "@mui/icons-material";
import { useAuth } from "../../services/auth";
import { usePrefs } from "../../services/prefs";
import { useAlert } from "../../services/alert";
import { useApiPrivate } from "../../api/api-private";
import ContentLayout from "../../layouts/ContentLayout";
import {
    getLocationNameLocalized,
    isStrongPassword,
    isValidEmail,
} from "../../helpers/utils";
import { User } from "../../models/user";
import { Role } from "../../models/role";
import { Location } from "../../models/location";

const UserForm = ({
    type,
    user,
}: {
    type: "new" | "edit" | "account";
    user?: User;
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const auth = useAuth();
    const prefs = usePrefs();
    const alert = useAlert();
    const apiPrivate = useApiPrivate();

    const [imageUrl] = useState<User["imageUrl"]>(user ? user.imageUrl : "");
    const [firstName, setFirstName] = useState<User["firstName"]>(
        user ? user.firstName : ""
    );
    const [lastName, setLastName] = useState<User["lastName"]>(
        user ? user.lastName : ""
    );
    const [email, setEmail] = useState<User["email"]>(user ? user.email : "");
    const [currentPassword, setCurrentPassword] =
        useState<User["password"]>("");
    const [password, setPassword] = useState<User["password"]>("");
    const [repeatPassword, setRepeatPassword] = useState<User["password"]>("");
    const [role, setRole] = useState<Role>(user ? user.authorities[0] : "");
    const [locations, setLocations] = useState<User["locations"]>(
        user ? user.locations : []
    );

    const [isPasswordRequired, setIsPasswordRequired] =
        useState<boolean>(false);
    const [showCurrentPassword, setShowCurrentPassword] =
        useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showRepeatPassword, setShowRepeatPassword] =
        useState<boolean>(false);

    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const [newImage, setNewImage] = useState<File | null>(null);
    const [isUploadOpen, setIsUploadOpen] = useState<boolean>(false);
    const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
    const [isUploadDisabled, setIsUploadDisabled] = useState<boolean>(true);

    const [isLocationsLoading, setIsLocationsLoading] = useState<boolean>(true);
    const [allLocations, setAllLocations] = useState<Location[]>([]);

    const handleUserSave = () => {
        setIsLoading(true);

        if (type === "new") {
            createUser();
        } else if (type === "edit") {
            updateUser();
        } else if (type === "account") {
            updateUser(isPasswordRequired);
        }
    };

    const getLocations = () => {
        apiPrivate
            .getLocations()
            .then((response) => {
                console.log("getLocations", response);

                const locations = response.data as Location[];

                setAllLocations(locations);

                if (user && user.locations && user.locations.length > 0) {
                    setLocations(
                        locations.filter((location) =>
                            user.locations.find(
                                (userLocation) =>
                                    userLocation.id === location.id
                            )
                        )
                    );
                }
            })
            .catch((error) => {
                console.error("getLocations", error);
            })
            .finally(() => {
                setIsLocationsLoading(false);
            });
    };

    const createUser = () => {
        apiPrivate
            .createUser(
                firstName,
                lastName,
                email,
                password,
                [role],
                mapLocations()
            )
            .then((response) => {
                console.log("createUser", response);

                navigate("/users");

                alert.show(t("messages.user-created"), "success");
            })
            .catch((error) => {
                console.error("createUser", error);

                alert.show(
                    error.response.data.errorKey === "userexists"
                        ? t("errors.user-exists")
                        : t("errors.generic"),
                    "error",
                    5000
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateUser = (withPassword = false) => {
        if (!user) return;

        apiPrivate
            .updateUser(
                user.id,
                firstName,
                lastName,
                email,
                imageUrl,
                [role],
                mapLocations()
            )
            .then((response) => {
                console.log("updateUser", response);

                if (type === "edit") {
                    navigate("/users");

                    alert.show(t("messages.user-updated"), "success");
                } else if (type === "account") {
                    if (withPassword) {
                        changeUserPassword();
                    } else {
                        navigate("/");

                        alert.show(
                            t("messages.account-updated"),
                            "success",
                            3000
                        );
                    }

                    auth.getCurrentUser();
                }
            })
            .catch((error) => {
                console.error("updateUser", error);

                alert.show(t("errors.generic"), "error");
            })
            .finally(() => {
                if (withPassword) return;

                setIsLoading(false);
            });
    };

    const changeUserPassword = () => {
        apiPrivate
            .updateCurrentUserPassword(currentPassword, password)
            .then((response) => {
                console.log("updateCurrentUserPassword", response);

                navigate("/");

                alert.show(t("messages.account-updated"), "success");
            })
            .catch((error) => {
                console.error("updateUser", error);

                alert.show(t("errors.generic"), "error");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleUpload = () => {
        if (!newImage) return;

        setIsUploadLoading(true);

        apiPrivate
            .uploadCurrentUserImage(newImage)
            .then((response) => {
                console.log("uploadCurrentUserImage", response);

                auth.getCurrentUser();

                navigate("/");

                alert.show(t("messages.account-image-updated"), "success");
            })
            .catch((error) => {
                console.error("uploadCurrentUserImage", error);

                alert.show(t("errors.generic"), "error");
            })
            .finally(() => {
                setIsUploadLoading(false);
            });
    };

    const onUploadOpen = () => {
        setNewImage(null);
        setIsUploadOpen(true);
        setIsUploadLoading(false);
        setIsUploadDisabled(true);
    };

    const onUploadClose = () => {
        setIsUploadOpen(false);
        setIsUploadLoading(false);
        setIsUploadDisabled(true);
    };

    const onUploadDrop = (files: File[]) => {
        setNewImage(files[0]);
        setIsUploadDisabled(false);
    };

    const mapLocations = () => {
        return locations.map((location) => location["id"]);
    };

    const toggleDisabled = () => {
        if (type === "new" || type === "edit") {
            setIsDisabled(
                firstName === "" ||
                    lastName === "" ||
                    email === "" ||
                    !isValidEmail(email) ||
                    role === ""
            );
        } else {
            if (isPasswordRequired) {
                setIsDisabled(
                    firstName === "" ||
                        lastName === "" ||
                        email === "" ||
                        !isValidEmail(email) ||
                        currentPassword === "" ||
                        password === "" ||
                        repeatPassword === "" ||
                        password !== repeatPassword ||
                        !isStrongPassword(currentPassword) ||
                        !isStrongPassword(password)
                );
            } else {
                setIsDisabled(
                    firstName === "" ||
                        lastName === "" ||
                        email === "" ||
                        !isValidEmail(email)
                );
            }
        }
    };

    useEffect(() => {
        toggleDisabled();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        firstName,
        lastName,
        email,
        role,
        locations,
        currentPassword,
        password,
        repeatPassword,
        isPasswordRequired,
    ]);

    useEffect(() => {
        if (type !== "account") {
            return;
        }

        if (
            currentPassword === "" &&
            password === "" &&
            repeatPassword === ""
        ) {
            setIsError(false);
            setIsPasswordRequired(false);
            return;
        }

        setIsError(
            password === "" || repeatPassword === ""
                ? false
                : password !== repeatPassword ||
                      !isStrongPassword(currentPassword) ||
                      !isStrongPassword(password)
        );
        setIsPasswordRequired(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPassword, password, repeatPassword]);

    useEffect(() => {
        getLocations();

        return () => apiPrivate.cancel();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <ContentLayout
                title={t("titles.profile") ?? ""}
                caption={t("captions.required-field") ?? ""}
            >
                <Grid container spacing={4}>
                    <Grid item xs={2}>
                        <Box
                            sx={{
                                position: "relative",
                            }}
                        >
                            <Avatar
                                src={imageUrl ?? undefined}
                                sx={{
                                    width: "100%",
                                    height: "auto",
                                    background: teal["A400"],
                                    color: teal[800],
                                    aspectRatio: 1,
                                }}
                            >
                                {!imageUrl && (
                                    <PersonOutlineOutlined
                                        sx={{
                                            fontSize: 70,
                                        }}
                                    />
                                )}
                            </Avatar>

                            {type === "account" && (
                                <IconButton
                                    onClick={onUploadOpen}
                                    sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        right: 0,
                                        color: "white",
                                        background: teal[800],
                                        "&:hover": {
                                            background: teal[900],
                                        },
                                    }}
                                >
                                    <AddPhotoAlternateOutlined
                                        sx={{
                                            fontSize: 18,
                                        }}
                                    />
                                </IconButton>
                            )}
                        </Box>
                    </Grid>

                    <Grid container item xs={10} spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="dense">
                                <TextField
                                    required
                                    label={t("inputs.first-name")}
                                    value={firstName}
                                    onChange={(event) =>
                                        setFirstName(event.target.value)
                                    }
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth margin="dense">
                                <TextField
                                    required
                                    label={t("inputs.last-name")}
                                    value={lastName}
                                    onChange={(event) =>
                                        setLastName(event.target.value)
                                    }
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth margin="dense">
                                <TextField
                                    required
                                    disabled={type !== "new"}
                                    label={t("inputs.email")}
                                    value={email}
                                    onChange={(event) =>
                                        setEmail(event.target.value)
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {type !== "account" && (
                            <Fragment>
                                <Grid item xs={12}>
                                    <Divider light />
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl fullWidth margin="dense">
                                        <InputLabel required>
                                            {t("inputs.role")}
                                        </InputLabel>

                                        <Select
                                            required
                                            label={t("inputs.role")}
                                            value={role}
                                            onChange={(event) =>
                                                setRole(
                                                    (event.target
                                                        .value as Role) ?? ""
                                                )
                                            }
                                        >
                                            <MenuItem value="ROLE_USER">
                                                {t("roles.user")}
                                            </MenuItem>

                                            <MenuItem value="ROLE_LOCATION_ADMIN">
                                                {t("roles.location-admin")}
                                            </MenuItem>

                                            <MenuItem value="ROLE_ADMIN">
                                                {t("roles.admin")}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <Autocomplete
                                        multiple
                                        loading={isLocationsLoading}
                                        options={allLocations}
                                        value={locations}
                                        onChange={(event, newLocations) =>
                                            setLocations(newLocations)
                                        }
                                        getOptionLabel={(location) =>
                                            getLocationNameLocalized(
                                                location,
                                                prefs.lang
                                            )
                                        }
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t("inputs.locations")}
                                                margin="dense"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Fragment>
                        )}

                        {type === "account" && (
                            <Fragment>
                                <Grid item xs={12}>
                                    <Divider light />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl fullWidth margin="dense">
                                        <TextField
                                            required={isPasswordRequired}
                                            type={
                                                showCurrentPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() =>
                                                                setShowCurrentPassword(
                                                                    (show) =>
                                                                        !show
                                                                )
                                                            }
                                                            edge="end"
                                                        >
                                                            {showCurrentPassword ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label={t("inputs.current-password")}
                                            value={currentPassword}
                                            onChange={(event) =>
                                                setCurrentPassword(
                                                    event.target.value
                                                )
                                            }
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl fullWidth margin="dense">
                                        <TextField
                                            required={isPasswordRequired}
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() =>
                                                                setShowPassword(
                                                                    (show) =>
                                                                        !show
                                                                )
                                                            }
                                                            edge="end"
                                                        >
                                                            {showPassword ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label={t("inputs.new-password")}
                                            value={password}
                                            onChange={(event) =>
                                                setPassword(event.target.value)
                                            }
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl fullWidth margin="dense">
                                        <TextField
                                            required={isPasswordRequired}
                                            type={
                                                showRepeatPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() =>
                                                                setShowRepeatPassword(
                                                                    (show) =>
                                                                        !show
                                                                )
                                                            }
                                                            edge="end"
                                                        >
                                                            {showRepeatPassword ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label={t(
                                                "inputs.repeat-new-password"
                                            )}
                                            value={repeatPassword}
                                            onChange={(event) =>
                                                setRepeatPassword(
                                                    event.target.value
                                                )
                                            }
                                            error={isError}
                                            helperText={
                                                isError
                                                    ? t("errors.password-rules")
                                                    : " "
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Fragment>
                        )}
                    </Grid>
                </Grid>
            </ContentLayout>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 4,
                }}
            >
                <LoadingButton
                    loading={isLoading}
                    disabled={isDisabled}
                    variant="contained"
                    disableElevation
                    onClick={handleUserSave}
                    startIcon={<SaveOutlined />}
                >
                    {t("buttons.save")}
                </LoadingButton>
            </Box>

            <Dialog open={isUploadOpen} onClose={onUploadClose}>
                <DialogContent>
                    <FormControl fullWidth>
                        <Dropzone
                            maxFiles={1}
                            maxSize={50000000}
                            onDrop={onUploadDrop}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <Card variant="outlined" {...getRootProps()}>
                                    <CardActionArea>
                                        <Box
                                            sx={{
                                                width: "250px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                padding: 2,
                                            }}
                                        >
                                            {newImage ? (
                                                <img
                                                    src={URL.createObjectURL(
                                                        newImage
                                                    )}
                                                    width="100"
                                                    height="100"
                                                    alt="Avatar"
                                                    style={{
                                                        objectFit: "contain",
                                                    }}
                                                />
                                            ) : imageUrl ? (
                                                <img
                                                    src={imageUrl}
                                                    width="100"
                                                    height="100"
                                                    alt="Avatar"
                                                    style={{
                                                        objectFit: "contain",
                                                    }}
                                                />
                                            ) : (
                                                <Skeleton
                                                    variant="circular"
                                                    width={100}
                                                    height={100}
                                                />
                                            )}

                                            <input
                                                {...getInputProps({
                                                    accept: "image/*",
                                                })}
                                            />
                                        </Box>
                                    </CardActionArea>
                                </Card>
                            )}
                        </Dropzone>

                        <FormHelperText
                            sx={{
                                marginX: 0,
                            }}
                        >
                            {t("inputs.upload")}
                        </FormHelperText>
                    </FormControl>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        loading={isUploadLoading}
                        disabled={isUploadDisabled}
                        disableElevation
                        onClick={handleUpload}
                    >
                        {t("buttons.save")}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default UserForm;
