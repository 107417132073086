import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../layouts/AdminLayout";
import ContentLayout from "../../layouts/ContentLayout";
import SettingsForm from "../../components/forms/SettingsForm";
import { useApiPrivate } from "../../api/api-private";
import { Setting } from "../../models/settings";

const Settings = () => {
    const { t } = useTranslation();
    const apiPrivate = useApiPrivate();

    const [settings, setSettings] = useState<Setting | null>(null);

    useEffect(() => {
        apiPrivate
            .getSettings()
            .then((response) => {
                console.log("getSettings", response);

                setSettings(response.data);
            })
            .catch((error) => {
                console.error("getSettings", error);
            });

        return () => apiPrivate.cancel();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AdminLayout title={t("titles.settings")}>
            {settings ? (
                <SettingsForm settings={settings} />
            ) : (
                <ContentLayout isLoading={true} />
            )}
        </AdminLayout>
    );
};

export default Settings;
