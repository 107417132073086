export const DEFAULT_LATITUDE = 42.650661 as const;
export const DEFAULT_LONGITUDE = 18.094423 as const;
export const DEFAULT_ZOOM = 10 as const;

export const DEFAULT_SETTINGS = {
    maximumReportingPeriodDays: 3,
    groupDataTimingMinutes: 30,
    autoplaySpeedMillis: 1000,
} as const;

export const FURIA_DATE_FORMAT = "yyyy-MM-dd HH:mm:ss" as const;
