import { useState } from "react";
import { createSingletonHook } from "../helpers/singleton";

type Alert = {
    message: string;
    type: "info" | "success" | "warning" | "error";
    duration: number;
    actionLink: string | null;
    actionLabel: string | null;
};

export const [useAlert, AlertProvider] = createSingletonHook(() => {
    const [message, setMessage] = useState<Alert["message"]>("");
    const [type, setType] = useState<Alert["type"]>("info");
    const [duration, setDuration] = useState<Alert["duration"]>(3000);
    const [actionLink, setActionLink] = useState<Alert["actionLink"]>(null);
    const [actionLabel, setActionLabel] = useState<Alert["actionLabel"]>(null);
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const show = (
        message: Alert["message"],
        type?: Alert["type"],
        duration?: Alert["duration"],
        actionLink?: Alert["actionLink"],
        actionLabel?: Alert["actionLabel"]
    ) => {
        setMessage(message);

        if (type) setType(type);
        if (duration) setDuration(duration);
        if (actionLink) setActionLink(actionLink);
        if (actionLabel) setActionLabel(actionLabel);

        setIsOpened(true);
    };

    const hide = () => {
        setIsOpened(false);
        setMessage("");
        setType("info");
        setActionLink(null);
        setActionLabel(null);
        setDuration(5000);
    };

    return {
        message,
        type,
        duration,
        actionLink,
        actionLabel,
        isOpened,
        show,
        hide,
    };
});
