import { Box } from "@mui/material";
import logo from "../../images/drural-logo.svg";

const Logo = () => {
    return (
        <Box
            sx={{
                display: "flex",
                marginX: 2,
            }}
        >
            <img
                src={logo}
                alt="dRural"
                style={{
                    width: "140px",
                    height: "50px",
                    objectFit: "contain",
                }}
            />
        </Box>
    );
};

export default Logo;
