import { createTheme } from "@mui/material/styles";
import { grey, teal } from "@mui/material/colors";

const theme = createTheme({
    palette: {
        primary: {
            main: teal[800],
        },
    },
    typography: {
        h1: {
            fontSize: "1.5rem",
            fontWeight: 400,
        },
        h2: {
            fontSize: "1.2rem",
            fontWeight: 400,
        },
        caption: {
            color: grey[700],
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 20,
                    textTransform: "initial",
                    minWidth: "auto",
                },
                text: {
                    borderRadius: 4,
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    background: "inherit",
                    "&:hover": {
                        backgroundColor: "rgba(0, 105, 92, 0.04)",
                    },
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    borderRadius: 30,
                    "&.Mui-selected": {
                        backgroundColor: teal[800],
                        color: "white",
                        "&:hover": {
                            backgroundColor: teal[800],
                        },
                        ".MuiListItemIcon-root": {
                            color: "white",
                        },
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:last-child td": {
                        border: 0,
                    },
                },
            },
        },
    },
});

export default theme;
