import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { SaveOutlined } from "@mui/icons-material";
import ContentLayout from "../../layouts/ContentLayout";
import { useApiPrivate } from "../../api/api-private";
import { useAlert } from "../../services/alert";
import { DEFAULT_SETTINGS } from "../../helpers/constants";
import { Setting } from "../../models/settings";

const SettingsForm = ({ settings }: { settings: Setting }) => {
    const { t } = useTranslation();
    const alert = useAlert();
    const apiPrivate = useApiPrivate();

    const [reportPeriod, setReportPeriod] = useState<
        Setting["maximumReportingPeriodDays"]
    >(
        settings.maximumReportingPeriodDays as Setting["maximumReportingPeriodDays"]
    );
    const [reportGrouping, setReportGrouping] = useState<
        Setting["groupDataTimingMinutes"]
    >(settings.groupDataTimingMinutes as Setting["groupDataTimingMinutes"]);
    const [reportSpeed, setReportSpeed] = useState<
        Setting["autoplaySpeedMillis"]
    >(settings.autoplaySpeedMillis as Setting["autoplaySpeedMillis"]);
    const [poiMeters, setPoiMeters] = useState<
        Setting["perimeterForPoiMeters"]
    >(settings.perimeterForPoiMeters);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSettingsSave = () => {
        setIsLoading(true);

        apiPrivate
            .updateSettings(
                reportPeriod,
                reportGrouping,
                reportSpeed,
                poiMeters
            )
            .then((response) => {
                console.log("updateSettings", response);

                alert.show(t("messages.settings-saved"), "success");
            })
            .catch((error) => {
                console.error("updateSettings", error);

                alert.show(t("errors.generic"), "error", 5000);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Fragment>
            <ContentLayout
                title={t("titles.reports") ?? ""}
                caption={t("captions.required-field") ?? ""}
            >
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <FormControl fullWidth margin="dense">
                            <InputLabel required>
                                {t("inputs.report-period")}
                            </InputLabel>

                            <Select
                                required
                                label={t("inputs.report-period")}
                                value={reportPeriod}
                                onChange={(event) =>
                                    setReportPeriod(
                                        (event.target
                                            .value as Setting["maximumReportingPeriodDays"]) ??
                                            DEFAULT_SETTINGS.maximumReportingPeriodDays
                                    )
                                }
                            >
                                <MenuItem value="1">
                                    {`1 ${t("inputs.days")}`}
                                </MenuItem>

                                <MenuItem value="2">
                                    {`2 ${t("inputs.days")}`}
                                </MenuItem>

                                <MenuItem value="3">
                                    {`3 ${t("inputs.days")}`}
                                </MenuItem>

                                <MenuItem value="4">
                                    {`4 ${t("inputs.days")}`}
                                </MenuItem>

                                <MenuItem value="5">
                                    {`5 ${t("inputs.days")}`}
                                </MenuItem>

                                <MenuItem value="6">
                                    {`6 ${t("inputs.days")}`}
                                </MenuItem>

                                <MenuItem value="7">
                                    {`7 ${t("inputs.days")}`}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl fullWidth margin="dense">
                            <InputLabel required>
                                {t("inputs.report-grouping")}
                            </InputLabel>

                            <Select
                                required
                                label={t("inputs.report-grouping")}
                                value={reportGrouping}
                                onChange={(event) =>
                                    setReportGrouping(
                                        (event.target
                                            .value as Setting["groupDataTimingMinutes"]) ??
                                            DEFAULT_SETTINGS.groupDataTimingMinutes
                                    )
                                }
                            >
                                <MenuItem value="15">
                                    {`15 ${t("inputs.minutes")}`}
                                </MenuItem>

                                <MenuItem value="30">
                                    {`30 ${t("inputs.minutes")}`}
                                </MenuItem>

                                <MenuItem value="45">
                                    {`45 ${t("inputs.minutes")}`}
                                </MenuItem>

                                <MenuItem value="60">
                                    {`60 ${t("inputs.minutes")}`}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl fullWidth margin="dense">
                            <InputLabel required>
                                {t("inputs.report-speed")}
                            </InputLabel>

                            <Select
                                required
                                label={t("inputs.report-speed")}
                                value={reportSpeed}
                                onChange={(event) =>
                                    setReportSpeed(
                                        (event.target
                                            .value as Setting["autoplaySpeedMillis"]) ??
                                            DEFAULT_SETTINGS.autoplaySpeedMillis
                                    )
                                }
                            >
                                <MenuItem value="500">
                                    {`0.5 ${t("inputs.seconds")}`}
                                </MenuItem>

                                <MenuItem value="1000">
                                    {`1 ${t("inputs.seconds")}`}
                                </MenuItem>

                                <MenuItem value="2000">
                                    {`2 ${t("inputs.seconds")}`}
                                </MenuItem>

                                <MenuItem value="3000">
                                    {`3 ${t("inputs.seconds")}`}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl fullWidth variant="outlined">
                            <TextField
                                required
                                variant="outlined"
                                label={t("inputs.poi-meters")}
                                value={poiMeters}
                                type="number"
                                onChange={(event) =>
                                    setPoiMeters(parseInt(event.target.value))
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {t("messages.meters")}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </ContentLayout>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 4,
                }}
            >
                <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    disableElevation
                    onClick={handleSettingsSave}
                    startIcon={<SaveOutlined />}
                >
                    {t("buttons.save")}
                </LoadingButton>
            </Box>
        </Fragment>
    );
};

export default SettingsForm;
