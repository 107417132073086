import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "../locales/en.json";
import hr from "../locales/hr.json";

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            lookupLocalStorage: "lang",
        },
        interpolation: { escapeValue: false },
        fallbackLng: "en",
        defaultNS: "drural",
        resources: {
            en: {
                drural: en,
            },
            hr: {
                drural: hr,
            },
        },
    })
    .then(() => {});

export default i18n;
