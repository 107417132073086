import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useApiPrivate } from "../../api/api-private";
import { usePrefs } from "../../services/prefs";
import { useAlert } from "../../services/alert";
import AdminLayout from "../../layouts/AdminLayout";
import ContentLayout from "../../layouts/ContentLayout";
import Search from "../../components/blocks/Search";
import Table from "../../components/blocks/Table";
import {
    getLocationNameLocalized,
    isSearchInLocation,
} from "../../helpers/utils";
import { Screen } from "../../models/screen";

const Screens = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const prefs = usePrefs();
    const alert = useAlert();
    const apiPrivate = useApiPrivate();

    const [screens, setScreens] = useState<Screen[]>([]);
    const [filteredScreens, setFilteredScreens] = useState<Screen[]>([]);
    const [search, setSearch] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getScreens = () => {
        apiPrivate
            .getScreens()
            .then((response) => {
                console.log("getScreens", response);

                setScreens(response.data);
                setFilteredScreens(response.data);
            })
            .catch((error) => {
                console.error("getScreens", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onScreenView = (locationId: string) => {
        const screen = screens.find(
            (screen) => screen.location.id === parseInt(locationId)
        );

        if (screen && screen.highlightColor) {
            window.open(`/welcome/${screen.token}`, "_blank");
        } else {
            alert.show(t("errors.screen-empty"), "warning");
        }
    };

    const onScreenEdit = (locationId: string) => {
        navigate(`/screens/${locationId}/edit`);
    };

    const screenColumns = () => {
        return [t("columns.name")];
    };

    const screenRows = () => {
        return filteredScreens.map((screen) => {
            return [
                screen.location.id.toString(),
                getLocationNameLocalized(screen.location, prefs.lang),
            ];
        });
    };

    useEffect(() => {
        setFilteredScreens(
            search !== ""
                ? screens.filter((screen) =>
                      isSearchInLocation(screen.location, search)
                  )
                : screens
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        getScreens();

        return () => apiPrivate.cancel();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AdminLayout title={t("titles.screens")}>
            <ContentLayout isLoading={isLoading} hasPadding={false}>
                <Search search={search} setSearch={setSearch} />

                <Table
                    type="screens"
                    columns={screenColumns()}
                    rows={screenRows()}
                    onView={onScreenView}
                    onEdit={onScreenEdit}
                />
            </ContentLayout>
        </AdminLayout>
    );
};

export default Screens;
