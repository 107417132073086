import axios, { AxiosError } from "axios";
import {
    UserForgotPassword,
    UserLogIn,
    UserRegister,
    UserResetPassword,
} from "../models/user";

export const useApiAuth = () => {
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    });

    const source = axios.CancelToken.source();
    const cancel = () => source.cancel();
    const isCancel = (error: AxiosError) => axios.isCancel(error);

    const logIn = (
        email: UserLogIn["email"],
        password: UserLogIn["password"],
        rememberMe: UserLogIn["rememberMe"]
    ) => {
        return api.post(
            "/authenticate",
            {
                email,
                password,
                rememberMe,
            },
            {
                cancelToken: source.token,
            }
        );
    };

    const register = (
        key: UserRegister["key"],
        password: UserRegister["password"]
    ) => {
        return api.post(
            "/account/reset-password/finish",
            {
                key,
                newPassword: password,
            },
            {
                cancelToken: source.token,
            }
        );
    };

    const forgotPassword = (email: UserForgotPassword["email"]) => {
        return api.post("/account/reset-password/init", email, {
            cancelToken: source.token,
        });
    };

    const resetPassword = (
        key: UserResetPassword["key"],
        password: UserResetPassword["password"]
    ) => {
        return api.post(
            "/account/reset-password/finish",
            {
                key,
                newPassword: password,
            },
            {
                cancelToken: source.token,
            }
        );
    };

    return {
        cancel,
        isCancel,

        logIn,
        register,
        forgotPassword,
        resetPassword,
    };
};
