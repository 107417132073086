import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useApiPrivate } from "../../api/api-private";
import { usePrefs } from "../../services/prefs";
import AdminLayout from "../../layouts/AdminLayout";
import ContentLayout from "../../layouts/ContentLayout";
import PoiForm from "../../components/forms/PoiForm";
import { getLocationNameLocalized } from "../../helpers/utils";
import { Location } from "../../models/location";

const NewPoi = () => {
    const { t } = useTranslation();
    const prefs = usePrefs();
    const { locationId } = useParams();
    const apiPrivate = useApiPrivate();

    const [location, setLocation] = useState<Location | null>(null);

    useEffect(() => {
        if (!locationId) return;

        apiPrivate
            .getLocation(parseInt(locationId))
            .then((response) => {
                console.log("getLocation", response);

                setLocation(response.data);
            })
            .catch((error) => {
                console.error("getLocation", error);
            });

        return () => apiPrivate.cancel();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationId]);

    return (
        <AdminLayout
            title={t("titles.new-poi")}
            breadcrumbs={[
                {
                    name: t("titles.locations"),
                    link: "/locations",
                },
                {
                    name: location
                        ? getLocationNameLocalized(location, prefs.lang)
                        : "...",
                    link: `/locations/${locationId}/pois`,
                },
            ]}
        >
            {location ? (
                <PoiForm type="new" location={location} />
            ) : (
                <ContentLayout isLoading={true} />
            )}
        </AdminLayout>
    );
};

export default NewPoi;
