import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useApiPrivate } from "../../api/api-private";
import AdminLayout from "../../layouts/AdminLayout";
import ContentLayout from "../../layouts/ContentLayout";
import ScreenForm from "../../components/forms/ScreenForm";
import { Screen } from "../../models/screen";

const EditScreen = () => {
    const { t } = useTranslation();
    const { locationId } = useParams();
    const apiPrivate = useApiPrivate();

    const [screen, setScreen] = useState<Screen | null>(null);

    useEffect(() => {
        if (!locationId) return;

        apiPrivate
            .getScreen(parseInt(locationId))
            .then((response) => {
                console.log("getScreen", response);

                setScreen(response.data);
            })
            .catch((error) => {
                console.error("getScreen", error);
            });

        return () => apiPrivate.cancel();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationId]);

    return (
        <AdminLayout
            title={t("titles.edit-screen")}
            breadcrumbs={[
                {
                    name: t("titles.screens"),
                    link: "/screens",
                },
            ]}
        >
            {screen ? (
                <ScreenForm screen={screen} />
            ) : (
                <ContentLayout isLoading={true} />
            )}
        </AdminLayout>
    );
};

export default EditScreen;
