import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useApiPrivate } from "../../api/api-private";
import AdminLayout from "../../layouts/AdminLayout";
import ContentLayout from "../../layouts/ContentLayout";
import LocationForm from "../../components/forms/LocationForm";
import { Location } from "../../models/location";

const EditLocation = () => {
    const { t } = useTranslation();
    const { locationId } = useParams();
    const apiPrivate = useApiPrivate();

    const [location, setLocation] = useState<Location | null>(null);

    useEffect(() => {
        if (!locationId) return;

        apiPrivate
            .getLocation(parseInt(locationId))
            .then((response) => {
                console.log("getLocation", response);

                setLocation(response.data);
            })
            .catch((error) => {
                console.error("getLocation", error);
            });

        return () => apiPrivate.cancel();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationId]);

    return (
        <AdminLayout
            title={t("titles.edit-location")}
            breadcrumbs={[
                {
                    name: t("titles.locations"),
                    link: "/locations",
                },
            ]}
        >
            {location ? (
                <LocationForm type="edit" location={location} />
            ) : (
                <ContentLayout isLoading={true} />
            )}
        </AdminLayout>
    );
};

export default EditLocation;
