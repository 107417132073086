import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useAuth } from "../../services/auth";

const Navigation = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();

    return (
        <List
            component="nav"
            sx={{
                marginTop: 1.5,
                marginRight: 3,
                maxWidth: 280,
            }}
        >
            <ListItemButton
                disableRipple
                selected={pathname === "/"}
                onClick={() => navigate("/")}
                sx={{
                    marginY: 1,
                }}
            >
                <ListItemIcon>
                    <SpaceDashboardOutlinedIcon />
                </ListItemIcon>

                <ListItemText primary={t("titles.dashboard")} />
            </ListItemButton>

            {(auth.isAdmin || auth.isLocationAdmin) && (
                <ListItemButton
                    disableRipple
                    disabled={
                        process.env.REACT_APP_FEATURE_LOCATIONS === "false"
                    }
                    selected={pathname.includes("/locations")}
                    onClick={() => navigate("/locations")}
                    sx={{
                        marginY: 1,
                    }}
                >
                    <ListItemIcon>
                        <LocationOnOutlinedIcon />
                    </ListItemIcon>

                    <ListItemText primary={t("titles.locations")} />
                </ListItemButton>
            )}

            {(auth.isAdmin || auth.isLocationAdmin) && (
                <ListItemButton
                    disableRipple
                    selected={pathname.includes("/users")}
                    onClick={() => navigate("/users")}
                    sx={{
                        marginY: 1,
                    }}
                >
                    <ListItemIcon>
                        <PeopleAltOutlinedIcon />
                    </ListItemIcon>

                    <ListItemText primary={t("titles.users")} />
                </ListItemButton>
            )}

            <ListItemButton
                disableRipple
                disabled={process.env.REACT_APP_FEATURE_REPORTS === "false"}
                selected={pathname.includes("/reports")}
                onClick={() => navigate("/reports")}
                sx={{
                    marginY: 1,
                }}
            >
                <ListItemIcon>
                    <AssessmentOutlinedIcon />
                </ListItemIcon>

                <ListItemText primary={t("titles.reports")} />
            </ListItemButton>

            {(auth.isAdmin || auth.isLocationAdmin) && (
                <ListItemButton
                    disableRipple
                    disabled={process.env.REACT_APP_FEATURE_SCREENS === "false"}
                    selected={pathname.includes("/screens")}
                    onClick={() => navigate("/screens")}
                    sx={{
                        marginY: 1,
                    }}
                >
                    <ListItemIcon>
                        <DevicesOutlinedIcon />
                    </ListItemIcon>

                    <ListItemText primary={t("titles.screens")} />
                </ListItemButton>
            )}

            {auth.isAdmin && (
                <ListItemButton
                    disableRipple
                    disabled={
                        process.env.REACT_APP_FEATURE_SETTINGS === "false"
                    }
                    selected={pathname.includes("/settings")}
                    onClick={() => navigate("/settings")}
                    sx={{
                        marginY: 1,
                    }}
                >
                    <ListItemIcon>
                        <SettingsOutlinedIcon />
                    </ListItemIcon>

                    <ListItemText primary={t("titles.settings")} />
                </ListItemButton>
            )}
        </List>
    );
};

export default Navigation;
