import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import _ from "lodash";
import { saveAs } from "file-saver";
import { usePrefs } from "../../services/prefs";
import { useAuth } from "../../services/auth";
import { useAnalytics } from "../../services/analytics";
import { useAlert } from "../../services/alert";
import { useApiPrivate } from "../../api/api-private";
import AdminLayout from "../../layouts/AdminLayout";
import ReportForm from "../../components/forms/ReportForm";
import ReportMap from "../../components/maps/ReportMap";
import { getLocationNameLocalized } from "../../helpers/utils";
import { DEFAULT_SETTINGS, FURIA_DATE_FORMAT } from "../../helpers/constants";
import { HeatMap, Location } from "../../models/location";
import { Setting } from "../../models/settings";

const Reports = () => {
    const { t } = useTranslation();
    const auth = useAuth();
    const prefs = usePrefs();
    const analytics = useAnalytics();
    const alert = useAlert();
    const apiPrivate = useApiPrivate();

    const [settings, setSettings] = useState<Setting | null>(null);
    const [location, setLocation] = useState<Location | null>(null);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isReportLoading, setIsReportLoading] = useState<boolean>(false);

    const [heatMapData, setHeatMapData] = useState<HeatMap[]>([]);
    const [groupedHeatMapData, setGroupedHeatMapData] = useState<HeatMap[][]>(
        []
    );

    const [isAutomaticInterval, setIsAutomaticInterval] =
        useState<boolean>(false);
    const [currentInterval, setCurrentInterval] = useState<number>(0);
    const [maxInterval, setMaxInterval] = useState<number>(0);

    const timeoutIntervalRef = useRef<ReturnType<typeof setTimeout> | null>(
        null
    );
    const currentIntervalRef = useRef(currentInterval);
    currentIntervalRef.current = currentInterval;

    const handleReport = () => {
        if (heatMapData.length === 0) {
            getReport();
        } else {
            resetReport();
        }
    };

    const getReport = () => {
        if (!location || !startDate || !endDate) return;

        setIsLoading(true);

        apiPrivate
            .getHeatMap(
                location.externalId,
                format(startDate, FURIA_DATE_FORMAT),
                format(endDate, FURIA_DATE_FORMAT)
            )
            .then((response) => {
                console.log("getHeatMap", response);

                const heatMapData = response.data as HeatMap[];

                if (heatMapData && heatMapData.length > 0) {
                    setHeatMapData(heatMapData);
                    groupHeatMapData(heatMapData);

                    analytics.sendEvent(
                        {
                            category: "report",
                            action: "show",
                            label: getLocationNameLocalized(
                                location,
                                prefs.lang
                            ),
                        },
                        auth.user
                    );
                } else {
                    resetReport();
                }
            })
            .catch((error) => {
                console.error("getHeatMap", error);
                resetReport();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleReportDownload = () => {
        if (!location || !startDate || !endDate || !settings) return;

        setIsReportLoading(true);

        apiPrivate
            .getHeatMapReport(
                location.externalId,
                format(startDate, FURIA_DATE_FORMAT),
                format(endDate, FURIA_DATE_FORMAT),
                settings.perimeterForPoiMeters
            )
            .then((response) => {
                saveAs(
                    new Blob([response.data], {
                        type: "text/csv;charset=utf-8",
                    }),
                    `dRural report ${getLocationNameLocalized(
                        location,
                        prefs.lang
                    )} (${format(startDate, FURIA_DATE_FORMAT)} - ${format(
                        endDate,
                        FURIA_DATE_FORMAT
                    )}).csv`
                );

                analytics.sendEvent(
                    {
                        category: "report",
                        action: "download",
                        label: getLocationNameLocalized(location, prefs.lang),
                    },
                    auth.user
                );
            })
            .catch((error) => {
                console.error("handleReportDownload", error);
            })
            .finally(() => {
                setIsReportLoading(false);
            });
    };

    const groupHeatMapData = (points: HeatMap[]) => {
        let intervalPoints: HeatMap[][];

        intervalPoints = _.chain(points)
            .groupBy((point) =>
                Math.floor(
                    +new Date(point.time) /
                        (1000 *
                            60 *
                            (settings
                                ? settings.groupDataTimingMinutes
                                : DEFAULT_SETTINGS.groupDataTimingMinutes))
                )
            )
            .sortBy((a, b) => b)
            .value();

        console.log("groupHeatMapData", intervalPoints);

        if (intervalPoints.length > 0) {
            setGroupedHeatMapData(intervalPoints);

            setCurrentInterval(0);
            setMaxInterval(intervalPoints.length - 1);
        } else {
            resetReport();
        }
    };

    const resetReport = () => {
        setHeatMapData([]);
        setGroupedHeatMapData([]);

        setCurrentInterval(0);
        setMaxInterval(0);

        stopAutomaticInterval();

        alert.show(t("errors.generic"), "error");
    };

    const handleAutomaticInterval = () => {
        if (isAutomaticInterval || maxInterval === 0) {
            stopAutomaticInterval();
        } else {
            startAutomaticInterval();
        }
    };

    const startAutomaticInterval = () => {
        setIsAutomaticInterval(true);

        timeoutIntervalRef.current = setInterval(
            () =>
                setCurrentInterval(
                    currentIntervalRef.current < maxInterval
                        ? currentIntervalRef.current + 1
                        : 0
                ),
            settings
                ? settings.autoplaySpeedMillis
                : DEFAULT_SETTINGS.autoplaySpeedMillis
        );
    };

    const stopAutomaticInterval = () => {
        if (timeoutIntervalRef.current)
            clearInterval(timeoutIntervalRef.current);

        setIsAutomaticInterval(false);
    };

    useEffect(() => {
        if (
            groupedHeatMapData.length > 0 &&
            currentInterval === 0 &&
            maxInterval !== 0 &&
            !timeoutIntervalRef.current
        ) {
            startAutomaticInterval();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupedHeatMapData, currentInterval, maxInterval]);

    useEffect(() => {
        apiPrivate
            .getSettings()
            .then((response) => {
                console.log("getSettings", response);

                setSettings(response.data);
            })
            .catch((error) => {
                console.error("getSettings", error);
            });

        return () => {
            apiPrivate.cancel();

            stopAutomaticInterval();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AdminLayout title={t("titles.reports")}>
            <ReportForm
                type="reports"
                settings={settings}
                location={location}
                setLocation={setLocation}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                isLoading={isLoading}
                handleReport={handleReport}
                isAutomaticInterval={isAutomaticInterval}
                handleAutomaticInterval={handleAutomaticInterval}
                maxInterval={maxInterval}
                currentInterval={currentInterval}
                setCurrentInterval={setCurrentInterval}
                heatMapData={heatMapData}
                groupedHeatMapData={groupedHeatMapData}
            />

            <ReportMap
                type="reports"
                location={location}
                currentInterval={currentInterval}
                heatMapData={heatMapData}
                groupedHeatMapData={groupedHeatMapData}
                isLoading={isLoading}
                isReportLoading={isReportLoading}
                handleReportDownload={handleReportDownload}
            />
        </AdminLayout>
    );
};

export default Reports;
