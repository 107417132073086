import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { useAuth } from "../services/auth";
import { useAnalytics } from "../services/analytics";
import { authRoutes } from "../routes/auth-routes";
import { privateRoutes } from "../routes/private-routes";
import { publicRoutes } from "../routes/public-routes";
import Error404 from "../pages/errors/Error404";

const Router = () => {
    const { pathname } = useLocation();
    const auth = useAuth();
    const analytics = useAnalytics();

    useEffect(() => {
        window.scrollTo(0, 0);

        analytics.sendPageView(pathname, auth.user);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return auth.isLoading ? (
        <Backdrop invisible open>
            <CircularProgress color="inherit" />
        </Backdrop>
    ) : (
        <Routes>
            {authRoutes.map((route, index) => (
                <Route
                    path={route.path}
                    element={<route.element />}
                    key={index}
                />
            ))}

            {publicRoutes.map((route, index) => (
                <Route
                    path={route.path}
                    element={<route.element />}
                    key={index}
                />
            ))}

            {privateRoutes.map((route, index) => (
                <Route
                    path={route.path}
                    element={
                        route.roles.some((role) =>
                            auth.user?.authorities.includes(role)
                        ) ? (
                            <route.element />
                        ) : (
                            <Navigate to="/login" />
                        )
                    }
                    key={index}
                />
            ))}

            <Route path="*" element={<Error404 />} />
        </Routes>
    );
};

export default Router;
