import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { useApiPrivate } from "../../api/api-private";
import { useAuth } from "../../services/auth";
import { usePrefs } from "../../services/prefs";
import { useAlert } from "../../services/alert";
import AdminLayout from "../../layouts/AdminLayout";
import ContentLayout from "../../layouts/ContentLayout";
import Search from "../../components/blocks/Search";
import Table from "../../components/blocks/Table";
import {
    getLocationNameLocalized,
    isSearchInLocation,
} from "../../helpers/utils";
import { Location } from "../../models/location";

const Locations = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const auth = useAuth();
    const prefs = usePrefs();
    const alert = useAlert();
    const apiPrivate = useApiPrivate();

    const [locations, setLocations] = useState<Location[]>([]);
    const [filteredLocations, setFilteredLocations] = useState<Location[]>([]);
    const [search, setSearch] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [deleteId, setDeleteId] = useState<string>("");
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);

    const getLocations = () => {
        apiPrivate
            .getLocations()
            .then((response) => {
                console.log("getLocations", response);

                setLocations(response.data);
                setFilteredLocations(response.data);
            })
            .catch((error) => {
                console.error("getLocations", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onLocationView = (locationId: string) => {
        navigate(`/locations/${locationId}/pois`);
    };

    const onLocationEdit = (locationId: string) => {
        navigate(`/locations/${locationId}/edit`);
    };

    const onLocationDelete = (locationId: string) => {
        setIsDeleteOpen(true);
        setDeleteId(locationId);
    };

    const onDeleteClose = () => {
        setIsDeleteOpen(false);
        setDeleteId("");
    };

    const onDeleteConfirm = () => {
        onDeleteClose();
        setIsLoading(true);

        apiPrivate
            .deleteLocation(parseInt(deleteId))
            .then((response) => {
                console.log("deleteLocation", response);

                getLocations();

                alert.show(t("messages.location-deleted"), "warning");
            })
            .catch((error) => {
                console.error("deleteLocation", error);

                alert.show(t("errors.generic"), "error");

                setIsLoading(false);
            });
    };

    const locationColumns = () => {
        return [
            t("columns.name"),
            t("columns.external-id"),
            t("columns.created-at"),
        ];
    };

    const locationRows = () => {
        return filteredLocations.map((location) => {
            return [
                location.id.toString(),
                getLocationNameLocalized(location, prefs.lang),
                location.externalId,
                new Date(location.ctime).toLocaleDateString(),
            ];
        });
    };

    useEffect(() => {
        setFilteredLocations(
            search !== ""
                ? locations.filter((location) =>
                      isSearchInLocation(location, search)
                  )
                : locations
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        getLocations();

        return () => apiPrivate.cancel();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AdminLayout title={t("titles.locations")}>
            <ContentLayout isLoading={isLoading} hasPadding={false}>
                <Search
                    search={search}
                    setSearch={setSearch}
                    buttonLink={auth.isAdmin ? "/locations/new" : undefined}
                    buttonLabel={
                        auth.isAdmin
                            ? t("buttons.new-location") ?? ""
                            : undefined
                    }
                />

                <Table
                    type="locations"
                    columns={locationColumns()}
                    rows={locationRows()}
                    onView={onLocationView}
                    onEdit={onLocationEdit}
                    onDelete={auth.isAdmin ? onLocationDelete : undefined}
                />
            </ContentLayout>

            <Dialog open={isDeleteOpen} onClose={onDeleteClose}>
                <DialogTitle>{t("titles.delete-location")}</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        {t("captions.delete-location")}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={onDeleteConfirm} color="error">
                        {t("buttons.confirm")}
                    </Button>
                </DialogActions>
            </Dialog>
        </AdminLayout>
    );
};

export default Locations;
