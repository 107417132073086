import { Route } from "../models/route";
import Dashboard from "../pages/Dashboard";
import Locations from "../pages/locations/Locations";
import NewLocation from "../pages/locations/NewLocation";
import EditLocation from "../pages/locations/EditLocation";
import Pois from "../pages/pois/Pois";
import NewPoi from "../pages/pois/NewPoi";
import EditPoi from "../pages/pois/EditPoi";
import Reports from "../pages/reports/Reports";
import Screens from "../pages/screens/Screens";
import EditScreen from "../pages/screens/EditScreen";
import Users from "../pages/users/Users";
import NewUser from "../pages/users/NewUser";
import EditUser from "../pages/users/EditUser";
import Account from "../pages/users/Account";
import Settings from "../pages/settings/Settings";

export const privateRoutes: Route[] = [
    {
        path: "/",
        element: Dashboard,
        roles: ["ROLE_ADMIN", "ROLE_LOCATION_ADMIN", "ROLE_USER"],
    },
    {
        path: "/locations",
        element: Locations,
        roles: ["ROLE_ADMIN", "ROLE_LOCATION_ADMIN"],
    },
    {
        path: "/locations/new",
        element: NewLocation,
        roles: ["ROLE_ADMIN"],
    },
    {
        path: "/locations/:locationId/edit",
        element: EditLocation,
        roles: ["ROLE_ADMIN", "ROLE_LOCATION_ADMIN"],
    },
    {
        path: "/locations/:locationId/pois",
        element: Pois,
        roles: ["ROLE_ADMIN", "ROLE_LOCATION_ADMIN"],
    },
    {
        path: "/locations/:locationId/pois/new",
        element: NewPoi,
        roles: ["ROLE_ADMIN", "ROLE_LOCATION_ADMIN"],
    },
    {
        path: "/locations/:locationId/pois/:poiId/edit",
        element: EditPoi,
        roles: ["ROLE_ADMIN", "ROLE_LOCATION_ADMIN"],
    },
    {
        path: "/reports",
        element: Reports,
        roles: ["ROLE_ADMIN", "ROLE_LOCATION_ADMIN"],
    },
    {
        path: "/screens",
        element: Screens,
        roles: ["ROLE_ADMIN", "ROLE_LOCATION_ADMIN"],
    },
    {
        path: "/screens/:locationId/edit",
        element: EditScreen,
        roles: ["ROLE_ADMIN", "ROLE_LOCATION_ADMIN"],
    },
    {
        path: "/users",
        element: Users,
        roles: ["ROLE_ADMIN", "ROLE_LOCATION_ADMIN"],
    },
    {
        path: "/users/new",
        element: NewUser,
        roles: ["ROLE_ADMIN", "ROLE_LOCATION_ADMIN"],
    },
    {
        path: "/users/:userId/edit",
        element: EditUser,
        roles: ["ROLE_ADMIN", "ROLE_LOCATION_ADMIN"],
    },
    {
        path: "/account",
        element: Account,
        roles: ["ROLE_ADMIN", "ROLE_LOCATION_ADMIN", "ROLE_USER"],
    },
    {
        path: "/settings",
        element: Settings,
        roles: ["ROLE_ADMIN"],
    },
];
