import { Box } from "@mui/material";
import Footer from "../footer/Footer";
import druralImage from "../../images/drural-auth.jpg";
import druralLogo from "../../images/drural-logo-white.svg";

const AuthImage = () => {
    return (
        <Box
            sx={{
                position: "relative",
                width: "100%",
                height: "100%",
            }}
        >
            <img
                src={druralImage}
                alt="dRural"
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "fill",
                }}
            />

            <Box
                sx={{
                    position: "absolute",
                    top: "100px",
                    left: "50%",
                    transform: "translateX(-50%)",
                }}
            >
                <img
                    src={druralLogo}
                    alt="dRural logo"
                    style={{
                        width: "135px",
                        height: "48px",
                        objectFit: "cover",
                    }}
                />
            </Box>

            <Box
                sx={{
                    position: "absolute",
                    bottom: 0,
                    paddingX: 2,
                    paddingY: 3,
                    color: "white",
                }}
            >
                <Footer />
            </Box>
        </Box>
    );
};

export default AuthImage;
