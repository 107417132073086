import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { usePrefs } from "../../services/prefs";
import Language from "../header/Language";
import { getLandingPageDetailsLocalized } from "../../helpers/utils";
import { Screen } from "../../models/screen";

const Intro = ({ screen }: { screen: Screen }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const prefs = usePrefs();
    const isMobileUp = useMediaQuery(theme.breakpoints.up("sm"));
    const isTabletUp = useMediaQuery(theme.breakpoints.up("md"));

    const screenDetails = getLandingPageDetailsLocalized(screen, prefs.lang);

    return (
        <Fragment>
            <Box
                sx={{
                    position: "relative",
                    width: "100vw",
                    height: isTabletUp ? "100vh" : isMobileUp ? "75vh" : "35vh",
                }}
            >
                {screen.coverPhotoUrl && (
                    <img
                        src={screen.coverPhotoUrl}
                        alt="cover"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                    />
                )}

                <Box
                    sx={{
                        position: "absolute",
                        top: "20px",
                        left: 0,
                        right: 0,
                        width: "100%",
                    }}
                >
                    <Container>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Language isPublic={true} />
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>

            <Container
                sx={{
                    position: "relative",
                    marginTop: isTabletUp
                        ? "-22.5vh"
                        : isMobileUp
                        ? "-12.5vh"
                        : "-8.5vh",
                }}
            >
                <Grid container spacing={4} justifyContent="stretch">
                    <Grid item xs={12} md={6}>
                        <Paper
                            elevation={0}
                            sx={{
                                padding: isTabletUp ? "40px" : "20px",
                                backgroundColor: screen.highlightColor,
                                color: "#fff",
                            }}
                        >
                            <Typography
                                variant="h1"
                                sx={{
                                    fontWeight: "700",
                                    fontSize: isTabletUp ? "64px" : "32px",
                                }}
                            >
                                {screenDetails?.title}
                            </Typography>

                            {screen.officialLogoUrl && (
                                <img
                                    src={screen.officialLogoUrl}
                                    alt="cover"
                                    style={{
                                        width: "65px",
                                        height: "80px",
                                        objectFit: "contain",
                                        marginTop: "30px",
                                    }}
                                />
                            )}
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Paper
                            variant={isTabletUp ? "outlined" : "elevation"}
                            elevation={0}
                            sx={{
                                padding: isTabletUp ? "40px" : 0,
                                height: "100%",
                            }}
                        >
                            <Typography variant="overline">
                                {t("titles.about")}
                            </Typography>

                            <Typography
                                component="h2"
                                variant="body1"
                                sx={{
                                    marginTop: "10px",
                                }}
                            >
                                {screenDetails?.shortDescription}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    );
};

export default Intro;
