import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format, subMinutes } from "date-fns";
import _ from "lodash";
import { useAuth } from "../services/auth";
import { usePrefs } from "../services/prefs";
import { useAnalytics } from "../services/analytics";
import { useAlert } from "../services/alert";
import { useApiPrivate } from "../api/api-private";
import AdminLayout from "../layouts/AdminLayout";
import ContentLayout from "../layouts/ContentLayout";
import ReportForm from "../components/forms/ReportForm";
import ReportMap from "../components/maps/ReportMap";
import { getLocationNameLocalized } from "../helpers/utils";
import { FURIA_DATE_FORMAT } from "../helpers/constants";
import { HeatMap, Location } from "../models/location";

const Dashboard = () => {
    const { t } = useTranslation();
    const auth = useAuth();
    const prefs = usePrefs();
    const analytics = useAnalytics();
    const alert = useAlert();
    const apiPrivate = useApiPrivate();

    const [location, setLocation] = useState<Location | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [heatMapData, setHeatMapData] = useState<HeatMap[]>([]);
    const [groupedHeatMapData, setGroupedHeatMapData] = useState<HeatMap[][]>(
        []
    );

    const getReport = () => {
        if (!location) return;

        setIsLoading(true);

        apiPrivate
            .getHeatMap(
                location.externalId,
                format(subMinutes(new Date(), 30), FURIA_DATE_FORMAT),
                format(new Date(), FURIA_DATE_FORMAT)
            )
            .then((response) => {
                console.log("getHeatMap", response);

                const heatMapData = response.data as HeatMap[];

                if (heatMapData && heatMapData.length > 0) {
                    setHeatMapData(heatMapData);
                    groupHeatMapData(heatMapData);

                    analytics.sendEvent(
                        {
                            category: "report",
                            action: "show",
                            label: getLocationNameLocalized(
                                location,
                                prefs.lang
                            ),
                        },
                        auth.user
                    );
                } else {
                    resetReport();
                }
            })
            .catch((error) => {
                console.error("getHeatMap", error);
                resetReport();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const groupHeatMapData = (points: HeatMap[]) => {
        let intervalPoints: HeatMap[][];

        intervalPoints = _.chain(points)
            .groupBy()
            .sortBy((a, b) => b)
            .value();

        console.log("groupHeatMapData", intervalPoints);

        if (intervalPoints.length > 0) {
            setGroupedHeatMapData(intervalPoints);
        } else {
            resetReport();
        }
    };

    const resetReport = () => {
        setHeatMapData([]);
        setGroupedHeatMapData([]);

        alert.show(t("errors.generic"), "error");
    };

    useEffect(() => {
        if (!location) return;

        getReport();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <AdminLayout title={t("titles.dashboard")}>
            {process.env.REACT_APP_FEATURE_REPORTS ? (
                <Fragment>
                    <ReportForm
                        type="dashboard"
                        settings={null}
                        location={location}
                        setLocation={setLocation}
                        startDate={null}
                        setStartDate={() => {}}
                        endDate={null}
                        setEndDate={() => {}}
                        isLoading={isLoading}
                        handleReport={() => {}}
                        isAutomaticInterval={false}
                        handleAutomaticInterval={() => {}}
                        maxInterval={0}
                        currentInterval={0}
                        setCurrentInterval={() => {}}
                        heatMapData={heatMapData}
                        groupedHeatMapData={groupedHeatMapData}
                    />

                    <ReportMap
                        type="dashboard"
                        location={location}
                        currentInterval={0}
                        heatMapData={heatMapData}
                        groupedHeatMapData={groupedHeatMapData}
                        isLoading={isLoading}
                    />
                </Fragment>
            ) : (
                <ContentLayout>{t("messages.work-in-progress")}</ContentLayout>
            )}
        </AdminLayout>
    );
};

export default Dashboard;
