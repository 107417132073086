import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { useApiPrivate } from "../../api/api-private";
import { useAuth } from "../../services/auth";
import { usePrefs } from "../../services/prefs";
import { useAlert } from "../../services/alert";
import AdminLayout from "../../layouts/AdminLayout";
import ContentLayout from "../../layouts/ContentLayout";
import Search from "../../components/blocks/Search";
import Table from "../../components/blocks/Table";
import {
    getLocationNameLocalized,
    getPoiNameLocalized,
    isSearchInPoi,
} from "../../helpers/utils";
import { Poi } from "../../models/poi";
import { Location } from "../../models/location";

const Pois = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const auth = useAuth();
    const prefs = usePrefs();
    const alert = useAlert();
    const { locationId } = useParams();
    const apiPrivate = useApiPrivate();

    const [location, setLocation] = useState<Location | null>(null);
    const [pois, setPois] = useState<Poi[]>([]);
    const [filteredPois, setFilteredPois] = useState<Poi[]>([]);
    const [search, setSearch] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [deleteId, setDeleteId] = useState<string>("");
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);

    const getLocation = () => {
        if (!locationId) return;

        apiPrivate
            .getLocation(parseInt(locationId))
            .then((response) => {
                console.log("getLocation", response);

                const location = response.data as Location;

                setLocation(location);

                setPois(location.pois ?? []);
                setFilteredPois(location.pois ?? []);
            })
            .catch((error) => {
                console.error("getLocation", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onPoiEdit = (poiId: string) => {
        if (!locationId) return;

        navigate(`/locations/${locationId}/pois/${poiId}/edit`);
    };

    const onPoiDelete = (poiId: string) => {
        setIsDeleteOpen(true);
        setDeleteId(poiId);
    };

    const onDeleteClose = () => {
        setIsDeleteOpen(false);
        setDeleteId("");
    };

    const onDeleteConfirm = () => {
        if (!locationId || !deleteId) return;

        onDeleteClose();
        setIsLoading(true);

        apiPrivate
            .deletePoi(parseInt(locationId), parseInt(deleteId))
            .then((response) => {
                console.log("deleteLocationPoi", response);

                getLocation();

                alert.show(t("messages.poi-deleted"), "warning");
            })
            .catch((error) => {
                console.error("deleteLocationPoi", error);

                alert.show(t("errors.generic"), "error");

                setIsLoading(false);
            });
    };

    const poiColumns = () => {
        return [t("columns.name"), t("columns.created-at")];
    };

    const poiRows = () => {
        return filteredPois.map((poi) => {
            return [
                poi.id.toString(),
                getPoiNameLocalized(poi, prefs.lang),
                new Date(poi.ctime).toLocaleDateString(),
            ];
        });
    };

    useEffect(() => {
        setFilteredPois(
            search !== ""
                ? pois.filter((poi) => isSearchInPoi(poi, search))
                : pois
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        if (!locationId) return;

        getLocation();

        return () => apiPrivate.cancel();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationId]);

    return (
        <AdminLayout
            title={t("titles.pois")}
            breadcrumbs={[
                {
                    name: t("titles.locations"),
                    link: "/locations",
                },
                {
                    name:
                        !isLoading && location
                            ? getLocationNameLocalized(location, prefs.lang)
                            : "...",
                    link: "/locations/" + locationId + "/pois",
                },
            ]}
        >
            <ContentLayout isLoading={isLoading} hasPadding={false}>
                <Search
                    search={search}
                    setSearch={setSearch}
                    buttonLink={
                        auth.isAdmin
                            ? `/locations/${locationId}/pois/new`
                            : undefined
                    }
                    buttonLabel={
                        auth.isAdmin ? t("buttons.new-poi") ?? "" : undefined
                    }
                />

                <Table
                    type="pois"
                    columns={poiColumns()}
                    rows={poiRows()}
                    onEdit={onPoiEdit}
                    onDelete={auth.isAdmin ? onPoiDelete : undefined}
                />
            </ContentLayout>

            <Dialog open={isDeleteOpen} onClose={onDeleteClose} maxWidth="xs">
                <DialogTitle>{t("titles.delete-poi")}</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        {t("captions.delete-poi")}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={onDeleteConfirm} color="error">
                        {t("buttons.confirm")}
                    </Button>
                </DialogActions>
            </Dialog>
        </AdminLayout>
    );
};

export default Pois;
