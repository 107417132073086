import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useApiPrivate } from "../../api/api-private";
import { usePrefs } from "../../services/prefs";
import AdminLayout from "../../layouts/AdminLayout";
import ContentLayout from "../../layouts/ContentLayout";
import PoiForm from "../../components/forms/PoiForm";
import {
    getLocationNameLocalized,
    getPoiNameLocalized,
} from "../../helpers/utils";
import { Location } from "../../models/location";
import { Poi } from "../../models/poi";

const EditPoi = () => {
    const { t } = useTranslation();
    const prefs = usePrefs();
    const { locationId, poiId } = useParams();
    const apiPrivate = useApiPrivate();

    const [location, setLocation] = useState<Location | null>(null);
    const [poi, setPoi] = useState<Poi | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!locationId || !poiId) return;

        apiPrivate
            .getLocation(parseInt(locationId))
            .then((response) => {
                console.log("getLocation", response);

                setLocation(response.data);
            })
            .catch((error) => {
                console.error("getLocation", error);
            });

        apiPrivate
            .getPoi(parseInt(locationId), parseInt(poiId))
            .then((response) => {
                console.log("getPoi", response);

                setPoi(response.data);
            })
            .catch((error) => {
                console.error("getPoi", error);
            });

        return () => apiPrivate.cancel();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationId, poiId]);

    useEffect(() => {
        if (location && poi) setIsLoading(false);
    }, [location, poi]);

    return (
        <AdminLayout
            title={t("titles.edit-poi")}
            breadcrumbs={[
                {
                    name: t("titles.locations"),
                    link: "/locations",
                },
                {
                    name: location
                        ? getLocationNameLocalized(location, prefs.lang)
                        : "...",
                    link: `/locations/${locationId}/pois`,
                },
                {
                    name: poi ? getPoiNameLocalized(poi, prefs.lang) : "...",
                    link: `/locations/${locationId}/pois/${poiId}/edit`,
                },
            ]}
        >
            {location && poi && !isLoading ? (
                <PoiForm type="edit" location={location} poi={poi} />
            ) : (
                <ContentLayout isLoading={true} />
            )}
        </AdminLayout>
    );
};

export default EditPoi;
