import { ReactNode } from "react";
import { Box, Container, Grid, Paper } from "@mui/material";
import { teal } from "@mui/material/colors";
import AuthImage from "../components/elements/AuthImage";
import Language from "../components/header/Language";

const AuthLayout = ({ children }: { children: ReactNode }) => {
    return (
        <Box
            sx={{
                background: teal[50],
                display: "flex",
                alignItems: "center",
                minHeight: "100vh",
                minWidth: "1000px",
            }}
        >
            <Container>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginY: 3,
                    }}
                >
                    <Language />
                </Box>

                <Paper elevation={0}>
                    <Grid container>
                        <Grid item xs={4}>
                            <AuthImage />
                        </Grid>

                        <Grid item xs={8}>
                            <Container
                                maxWidth="xs"
                                sx={{
                                    height: "100%",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        height: "100%",
                                    }}
                                >
                                    {children}
                                </Box>
                            </Container>
                        </Grid>
                    </Grid>
                </Paper>

                <Box
                    sx={{
                        height: "30px",
                        marginY: 3,
                    }}
                />
            </Container>
        </Box>
    );
};

export default AuthLayout;
