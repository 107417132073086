import { useTranslation } from "react-i18next";
import {
    Box,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { usePrefs } from "../../services/prefs";
import i18n from "../../services/i18n";
import flagEn from "../../images/flag-en.svg";
import flagHr from "../../images/flag-hr.svg";
import { Lang } from "../../models/prefs";

const Language = ({ isPublic = false }: { isPublic?: boolean }) => {
    const { t } = useTranslation();
    const prefs = usePrefs();

    const handleChange = (event: SelectChangeEvent) => {
        const newLang = event.target.value as Lang;

        i18n.changeLanguage(newLang).then(() => {
            prefs.setLang(newLang);
        });
    };

    return (
        <FormControl
            variant="filled"
            size="small"
            hiddenLabel
            sx={{
                position: "relative",
                zIndex: 10000,
            }}
        >
            <Select
                value={prefs.lang}
                onChange={handleChange}
                disableUnderline
                sx={
                    isPublic
                        ? {
                              backgroundColor: "#fff",
                              borderRadius: "20px",
                              display: "flex",
                              alignItems: "center",
                              "&:hover": {
                                  backgroundColor: "#fff",
                                  opacity: 0.8,
                              },
                          }
                        : {}
                }
            >
                <MenuItem
                    value="en"
                    sx={{
                        position: "relative",
                        padding: "8px",
                        minHeight: "auto",
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: "10px",
                        }}
                    >
                        <img
                            src={flagEn}
                            alt="en"
                            style={{
                                width: "20px",
                                height: "20px",
                                objectFit: "cover",
                                borderRadius: "100%",
                            }}
                        />
                    </Box>

                    <Box
                        sx={{
                            paddingLeft: "30px",
                        }}
                    >
                        {t("languages.english")}
                    </Box>
                </MenuItem>

                <MenuItem
                    value="hr"
                    sx={{
                        position: "relative",
                        padding: "8px",
                        minHeight: "auto",
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: "10px",
                        }}
                    >
                        <img
                            src={flagHr}
                            alt="hr"
                            style={{
                                width: "20px",
                                height: "20px",
                                objectFit: "cover",
                                borderRadius: "100%",
                            }}
                        />
                    </Box>

                    <Box
                        sx={{
                            paddingLeft: "30px",
                        }}
                    >
                        {t("languages.croatian")}
                    </Box>
                </MenuItem>
            </Select>
        </FormControl>
    );
};

export default Language;
