import { useTranslation } from "react-i18next";
import AdminLayout from "../../layouts/AdminLayout";
import UserForm from "../../components/forms/UserForm";

const NewUser = () => {
    const { t } = useTranslation();

    return (
        <AdminLayout
            title={t("titles.new-user")}
            breadcrumbs={[
                {
                    name: t("titles.users"),
                    link: "/users",
                },
            ]}
        >
            <UserForm type="new" />
        </AdminLayout>
    );
};

export default NewUser;
