import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { useApiPublic } from "../api/api-public";
import Intro from "../components/welcome/Intro";
import Places from "../components/welcome/Places";
import Footer from "../components/footer/Footer";
import Consent from "../components/welcome/Consent";
import { Screen } from "../models/screen";

const Welcome = () => {
    const { token } = useParams();

    const apiPublic = useApiPublic();

    const [screen, setScreen] = useState<Screen | null>(null);

    useEffect(() => {
        if (!token) return;

        apiPublic
            .getScreen(token)
            .then((response) => {
                console.log("getScreen", response);

                setScreen(response.data);
            })
            .catch((error) => {
                console.error("getScreen", error);
            });

        return () => apiPublic.cancel();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    return screen ? (
        <Fragment>
            <Intro screen={screen} />

            <Places screen={screen} />

            <Consent screen={screen} />

            <Footer isWelcome={true} />
        </Fragment>
    ) : (
        <Backdrop invisible open>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default Welcome;
