import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import en from "date-fns/locale/en-GB";
import hr from "date-fns/locale/hr";
import CssBaseline from "@mui/material/CssBaseline";
import { PrefsProvider, usePrefs } from "../services/prefs";
import { AuthProvider } from "../services/auth";
import { AlertProvider } from "../services/alert";
import { AnalyticsProvider, useAnalytics } from "../services/analytics";
import Router from "./Router";
import theme from "../styles/theme";
import Compose from "../helpers/compose";
import "../services/i18n";
import "../styles/styles";

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />

            <Compose
                components={[
                    BrowserRouter,
                    PrefsProvider,
                    AnalyticsProvider,
                    AuthProvider,
                    AlertProvider,
                ]}
            >
                <AppRouter />
            </Compose>
        </ThemeProvider>
    );
};

const AppRouter = () => {
    const prefs = usePrefs();
    const analytics = useAnalytics();

    useEffect(() => {
        analytics.initGA();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <I18nextProvider i18n={i18next}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={prefs.lang === "hr" ? hr : en}
            >
                <Router />
            </LocalizationProvider>
        </I18nextProvider>
    );
};

export default App;
