import { Fragment, useEffect, useState } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { usePrefs } from "../../services/prefs";
import { useApiPublic } from "../../api/api-public";
import {
    getMapMarkers,
    getMapZoom,
    getPoiLocalized,
} from "../../helpers/utils";
import Map from "../maps/Map";
import { Screen } from "../../models/screen";
import { HeatMap } from "../../models/location";

const Places = ({ screen }: { screen: Screen }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const prefs = usePrefs();
    const apiPublic = useApiPublic();

    const [groupedHeatMapData, setGroupedHeatMapData] = useState<HeatMap[][]>(
        []
    );

    const isMobileUp = useMediaQuery(theme.breakpoints.up("sm"));
    const isTabletUp = useMediaQuery(theme.breakpoints.up("md"));

    const getReport = () => {
        apiPublic
            .getHeatMap(screen.location.externalId)
            .then((response) => {
                console.log("getHeatMap", response);

                const heatMapData = response.data as HeatMap[];

                if (heatMapData && heatMapData.length > 0) {
                    let intervalPoints: HeatMap[][];

                    intervalPoints = _.chain(heatMapData)
                        .groupBy((point) =>
                            Math.floor(+new Date(point.time) / (1000 * 60 * 60))
                        )
                        .sortBy((a, b) => b)
                        .value();

                    console.log("groupHeatMapData", intervalPoints);

                    if (intervalPoints.length > 0) {
                        setGroupedHeatMapData(intervalPoints);
                    }
                }
            })
            .catch((error) => {
                console.error("getHeatMap", error);

                setGroupedHeatMapData([]);
            });
    };

    useEffect(() => {
        if (screen.mapEnabled && screen.mapHeatMapEnabled) getReport();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screen]);

    return screen.poisSelected && screen.poisSelected.length > 0 ? (
        <Box
            sx={{
                marginTop: isTabletUp ? 8 : isMobileUp ? 6 : 4,
            }}
        >
            <Container>
                <Typography
                    component="h2"
                    variant="overline"
                    sx={{
                        marginBottom: 2,
                    }}
                >
                    {t("titles.places")}
                </Typography>

                <Grid container spacing={4}>
                    {screen.poisSelected.map((poi) => {
                        const poiDetails = getPoiLocalized(poi, prefs.lang);

                        return (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                key={poi.id}
                                sx={{
                                    marginBottom: isTabletUp ? 2 : 1,
                                }}
                            >
                                {poi.imageUrl && (
                                    <img
                                        src={poi.imageUrl}
                                        alt={poiDetails?.name}
                                        style={{
                                            width: "100%",
                                            height: isTabletUp
                                                ? "300px"
                                                : "200px",
                                            objectFit: "cover",
                                        }}
                                    />
                                )}

                                <Typography
                                    component="h2"
                                    variant="h6"
                                    sx={{
                                        marginY: 1,
                                    }}
                                >
                                    {poiDetails?.name}
                                </Typography>

                                <Typography>
                                    {poiDetails?.description}
                                </Typography>

                                {(poiDetails?.url || poiDetails?.buyButton) && (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: 2,
                                            marginTop: 2,
                                        }}
                                    >
                                        {poiDetails.url && (
                                            <Button
                                                variant="outlined"
                                                color="info"
                                                onClick={() => {
                                                    window.open(
                                                        poiDetails.url,
                                                        "_blank"
                                                    );
                                                }}
                                                sx={{
                                                    color: screen.highlightColor,
                                                    borderColor:
                                                        screen.highlightColor,
                                                }}
                                            >
                                                {t("buttons.visit")}
                                            </Button>
                                        )}

                                        {poiDetails.buyButton && (
                                            <Button
                                                variant="contained"
                                                color="info"
                                                disableElevation
                                                onClick={() => {
                                                    window.open(
                                                        poiDetails.buyButton,
                                                        "_blank"
                                                    );
                                                }}
                                                sx={{
                                                    backgroundColor:
                                                        screen.highlightColor,
                                                    borderColor:
                                                        screen.highlightColor,
                                                }}
                                            >
                                                {t("buttons.buy")}
                                            </Button>
                                        )}
                                    </Box>
                                )}
                            </Grid>
                        );
                    })}
                </Grid>

                {screen.mapEnabled && (
                    <Box
                        sx={{
                            marginTop: 6,
                        }}
                    >
                        {screen.mapHeatMapEnabled ? (
                            <Map
                                zoom={getMapZoom(screen.location)}
                                markers={getMapMarkers(screen.location)}
                                points={groupedHeatMapData}
                                interval={0}
                            />
                        ) : (
                            <Map
                                zoom={getMapZoom(screen.location)}
                                markers={getMapMarkers(screen.location)}
                            />
                        )}
                    </Box>
                )}
            </Container>
        </Box>
    ) : (
        <Fragment />
    );
};

export default Places;
