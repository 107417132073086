import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputAdornment,
    TextField,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const Search = ({
    search,
    setSearch,
    buttonLink,
    buttonLabel,
}: {
    search: string;
    setSearch: Dispatch<SetStateAction<string>>;
    buttonLink?: string;
    buttonLabel?: string;
}) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                padding: 2,
                position: "sticky",
                top: "100px",
                background: "white",
                zIndex: 1,
            }}
        >
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs>
                    <FormControl fullWidth variant="outlined">
                        <TextField
                            variant="outlined"
                            type="search"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchOutlinedIcon />
                                    </InputAdornment>
                                ),
                            }}
                            size="small"
                            hiddenLabel
                            placeholder={t("inputs.search") ?? ""}
                            value={search}
                            onChange={(event) => setSearch(event.target.value)}
                        />
                    </FormControl>
                </Grid>

                {buttonLink && buttonLabel && (
                    <Grid item>
                        <Box
                            sx={{
                                marginLeft: 2,
                            }}
                        >
                            <Link to={buttonLink}>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    startIcon={<AddOutlinedIcon />}
                                >
                                    {buttonLabel}
                                </Button>
                            </Link>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default Search;
