import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Drawer,
    Grid,
    Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { usePrefs } from "../../services/prefs";
import { useAnalytics } from "../../services/analytics";
import { useAlert } from "../../services/alert";
import {
    getLandingPageDetailsLocalized,
    getLocationNameLocalized,
} from "../../helpers/utils";
import { Screen } from "../../models/screen";

const Consent = ({ screen }: { screen: Screen }) => {
    const { t } = useTranslation();
    const prefs = usePrefs();
    const theme = useTheme();
    const analytics = useAnalytics();
    const alert = useAlert();
    const isMobileUp = useMediaQuery(theme.breakpoints.up("sm"));

    const screenDetails = getLandingPageDetailsLocalized(screen, prefs.lang);

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const merakiLoginUrl = query.get("base_grant_url");
    const isSuccess = location.pathname.includes("success");

    const [isTermsOpen, setIsTermsOpen] = useState(false);
    const [isCookiesOpen, setIsCookiesOpen] = useState(false);

    const handleConsent = () => {
        if (!screen.token || !merakiLoginUrl) {
            console.error("handleConsent", query);

            alert.show(t("messages.internet-denied"), "error");

            analytics.sendEvent(
                {
                    category: "visitor",
                    action: "denied",
                    label: getLocationNameLocalized(
                        screen.location,
                        prefs.lang,
                    ),
                },
                null,
            );

            return;
        }

        window.location.href = `${merakiLoginUrl}?duration=${
            process.env.REACT_APP_MERAKI_DURATION || "3600"
        }&continue_url=${process.env.REACT_APP_URL}/welcome/${
            screen.token
        }/success`;
    };

    useEffect(() => {
        if (screen.token && isSuccess) {
            alert.show(t("messages.internet-granted"), "success");

            analytics.sendEvent(
                {
                    category: "visitor",
                    action: "granted",
                    label: getLocationNameLocalized(
                        screen.location,
                        prefs.lang,
                    ),
                },
                null,
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Drawer
            anchor="bottom"
            open={!isSuccess}
            ModalProps={{ disableScrollLock: true }}
        >
            <Container
                sx={{
                    paddingY: isMobileUp ? 6 : 4,
                }}
            >
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <Typography variant={isMobileUp ? "h5" : "h6"}>
                            {t("titles.consent")}
                        </Typography>

                        <Typography
                            sx={{
                                marginTop: 1,
                            }}
                        >
                            {t("captions.consent")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <LoadingButton
                                variant="contained"
                                color="info"
                                disableElevation
                                onClick={handleConsent}
                                sx={{
                                    backgroundColor: screen.highlightColor,
                                    borderColor: screen.highlightColor,
                                }}
                            >
                                {t("buttons.consent")}
                            </LoadingButton>
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                gap: 2,
                                marginTop: 2,
                            }}
                        >
                            <Button
                                variant="text"
                                color="info"
                                onClick={() => setIsTermsOpen(true)}
                                sx={{
                                    color: screen.highlightColor,
                                }}
                            >
                                {t("titles.terms")}
                            </Button>

                            <Button
                                variant="text"
                                color="info"
                                onClick={() => setIsCookiesOpen(true)}
                                sx={{
                                    color: screen.highlightColor,
                                }}
                            >
                                {t("titles.cookies")}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={isTermsOpen}
                onClose={() => setIsTermsOpen(false)}
            >
                <DialogTitle>{t("titles.terms")}</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        {screenDetails?.termsOfUse}
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={isCookiesOpen}
                onClose={() => setIsCookiesOpen(false)}
            >
                <DialogTitle>{t("titles.cookies")}</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        {screenDetails?.gdprTermsOfUse}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Drawer>
    );
};

export default Consent;
