import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import AuthLayout from "../../layouts/AuthLayout";
import { useAuth } from "../../services/auth";
import { isValidEmail } from "../../helpers/utils";
import { User } from "../../models/user";

const LogIn = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const auth = useAuth();

    const [email, setEmail] = useState<User["email"]>("");
    const [password, setPassword] = useState<User["password"]>("");
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleLogIn = () => {
        if (auth.isLogInOut || !email || !password) return;

        auth.logIn(email, password, rememberMe);
    };

    useEffect(() => {
        auth.setIsError(false);

        setIsDisabled(email === "" || password === "" || !isValidEmail(email));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, password]);

    useEffect(() => {
        if (auth.user) navigate("/");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.user]);

    return (
        <AuthLayout>
            <Typography variant="h1">{t("titles.log-in")}</Typography>

            <Typography variant="caption">{t("captions.log-in")}</Typography>

            <Box
                sx={{
                    marginTop: 6,
                }}
            >
                <FormControl fullWidth margin="normal">
                    <TextField
                        type="email"
                        label={t("inputs.email")}
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        error={auth.isError}
                    />
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <TextField
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            setShowPassword((show) => !show)
                                        }
                                        edge="end"
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        label={t("inputs.password")}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        error={auth.isError}
                        helperText={
                            auth.isError ? t("errors.missing-account") : " "
                        }
                    />
                </FormControl>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Link to="/forgot-password">
                        <Button size="small">
                            {t("buttons.forgot-password")}
                        </Button>
                    </Link>

                    <Box
                        sx={{
                            display: "flex",
                            alignContent: "center",
                        }}
                    >
                        <FormControlLabel
                            control={<Checkbox size="small" />}
                            label={t("buttons.remember-me")}
                            value={rememberMe}
                            onChange={() => setRememberMe(!rememberMe)}
                            disableTypography
                            sx={{
                                fontSize: "0.8rem",
                            }}
                        />

                        <LoadingButton
                            loading={auth.isLogInOut}
                            disabled={isDisabled}
                            variant="contained"
                            disableElevation
                            onClick={handleLogIn}
                        >
                            {t("buttons.log-in")}
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
        </AuthLayout>
    );
};

export default LogIn;
