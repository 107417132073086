import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "@mui/material";
import flagEn from "../../images/flag-en.svg";
import flagHr from "../../images/flag-hr.svg";

const Translations = ({
    tab,
    setTab,
}: {
    tab: number;
    setTab: Dispatch<SetStateAction<number>>;
}) => {
    const { t } = useTranslation();

    return (
        <Tabs
            value={tab}
            onChange={(event, newTab) => setTab(newTab)}
            sx={{
                marginBottom: 2,
            }}
        >
            <Tab
                icon={
                    <img
                        src={flagEn}
                        alt="EN"
                        style={{
                            width: "20px",
                            height: "20px",
                            objectFit: "cover",
                            borderRadius: "100%",
                        }}
                    />
                }
                iconPosition="start"
                label={t("languages.english")}
                sx={{
                    minHeight: "auto",
                    maxWidth: "initial",
                    width: "50%",
                }}
            />

            <Tab
                icon={
                    <img
                        src={flagHr}
                        alt="HR"
                        style={{
                            width: "20px",
                            height: "20px",
                            objectFit: "cover",
                            borderRadius: "100%",
                        }}
                    />
                }
                iconPosition="start"
                label={t("languages.croatian")}
                sx={{
                    minHeight: "auto",
                    maxWidth: "initial",
                    width: "50%",
                }}
            />
        </Tabs>
    );
};

export default Translations;
