import { JSXElementConstructor, PropsWithChildren, ReactNode } from "react";

export default function Compose(props: {
    components: Array<JSXElementConstructor<PropsWithChildren<unknown>>>;
    children: ReactNode;
}) {
    const { components = [], children } = props;

    return (
        <>
            {components.reduceRight((component, Component) => {
                return <Component>{component}</Component>;
            }, children)}
        </>
    );
}
