import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { add, format, sub } from "date-fns";
import hr from "date-fns/locale/hr";
import en from "date-fns/locale/en-GB";
import {
    Alert,
    Autocomplete,
    Box,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Slider,
    Stack,
    TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
    ModeOfTravelOutlined,
    PlayCircleOutlineOutlined,
    StopCircleOutlined,
} from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import ContentLayout from "../../layouts/ContentLayout";
import { usePrefs } from "../../services/prefs";
import { useApiPrivate } from "../../api/api-private";
import { getLocationNameLocalized } from "../../helpers/utils";
import { DEFAULT_SETTINGS } from "../../helpers/constants";
import { HeatMap, Location } from "../../models/location";
import { Setting } from "../../models/settings";

const ReportForm = ({
    type,
    settings,
    location,
    setLocation,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isLoading,
    handleReport,
    isAutomaticInterval,
    handleAutomaticInterval,
    maxInterval,
    currentInterval,
    setCurrentInterval,
    heatMapData,
    groupedHeatMapData,
}: {
    type: "reports" | "dashboard";
    settings: Setting | null;
    location: Location | null;
    setLocation: (location: Location | null) => void;
    startDate: Date | null;
    setStartDate: (startDate: Date | null) => void;
    endDate: Date | null;
    setEndDate: (endDate: Date | null) => void;
    isLoading: boolean;
    handleReport: () => void;
    isAutomaticInterval: boolean;
    handleAutomaticInterval: () => void;
    maxInterval: number;
    currentInterval: number;
    setCurrentInterval: (currentInterval: number) => void;
    heatMapData: HeatMap[];
    groupedHeatMapData: HeatMap[][];
}) => {
    const { t } = useTranslation();
    const prefs = usePrefs();
    const apiPrivate = useApiPrivate();

    const isReportLoaded = heatMapData.length > 0;

    const [locations, setLocations] = useState<Location[]>([]);
    const [isLocationsLoading, setIsLocationsLoading] = useState<boolean>(true);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const getLocations = () => {
        apiPrivate
            .getLocations()
            .then((response) => {
                console.log("getLocations", response);

                setLocations(response.data);
            })
            .catch((error) => {
                console.error("getLocations", error);
            })
            .finally(() => {
                setIsLocationsLoading(false);
            });
    };

    const sliderLabel = (currentInterval: number) => {
        return groupedHeatMapData.length > 0
            ? `${format(
                  new Date(
                      groupedHeatMapData[currentInterval][0].time.slice(0, -1)
                  ),
                  "Pp",
                  { locale: prefs.lang === "hr" ? hr : en }
              )} - ${format(
                  new Date(
                      groupedHeatMapData[currentInterval][
                          groupedHeatMapData[currentInterval].length - 1
                      ].time.slice(0, -1)
                  ),
                  "Pp",
                  { locale: prefs.lang === "hr" ? hr : en }
              )}`
            : "";
    };

    useEffect(() => {
        setIsDisabled(!location || !startDate || !endDate);
    }, [location, startDate, endDate]);

    useEffect(() => {
        if (!isReportLoaded) {
            setLocation(null);
            setStartDate(null);
            setEndDate(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReportLoaded]);

    useEffect(() => {
        getLocations();

        return () => apiPrivate.cancel();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentLayout>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Autocomplete
                        disabled={
                            type === "reports"
                                ? isLoading || isReportLoaded
                                : isLoading
                        }
                        loading={
                            type === "reports"
                                ? isLocationsLoading
                                : isLoading || isLocationsLoading
                        }
                        options={locations}
                        value={location}
                        onChange={(event, newLocation) =>
                            setLocation(newLocation)
                        }
                        getOptionLabel={(location) =>
                            getLocationNameLocalized(location, prefs.lang)
                        }
                        filterSelectedOptions
                        isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("inputs.location")}
                                margin="none"
                                required
                            />
                        )}
                    />
                </Grid>

                {type === "dashboard" && (
                    <Grid item xs={4}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                height: "100%",
                            }}
                        >
                            <Alert
                                severity="info"
                                variant="outlined"
                                sx={{
                                    border: "none",
                                    color: grey[700],
                                }}
                            >
                                {t("messages.current-situation")}
                            </Alert>
                        </Box>
                    </Grid>
                )}

                {type === "reports" && (
                    <Fragment>
                        <Grid item xs={3}>
                            <FormControl fullWidth margin="none">
                                <DateTimePicker
                                    value={startDate}
                                    onChange={(newStartDate) =>
                                        setStartDate(newStartDate)
                                    }
                                    label={t("inputs.start-date")}
                                    disabled={isLoading || isReportLoaded}
                                    disableFuture
                                    slotProps={{
                                        textField: {
                                            required: true,
                                        },
                                        actionBar: {
                                            actions: ["clear", "accept"],
                                        },
                                    }}
                                    minDateTime={
                                        endDate
                                            ? sub(endDate, {
                                                  days: settings
                                                      ? settings.maximumReportingPeriodDays
                                                      : DEFAULT_SETTINGS.maximumReportingPeriodDays,
                                              })
                                            : null
                                    }
                                    maxDateTime={endDate ?? null}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth margin="none">
                                <DateTimePicker
                                    value={endDate}
                                    onChange={(newEndDate) =>
                                        setEndDate(newEndDate)
                                    }
                                    label={t("inputs.end-date")}
                                    disabled={isLoading || isReportLoaded}
                                    disableFuture
                                    slotProps={{
                                        textField: {
                                            required: true,
                                        },
                                        actionBar: {
                                            actions: ["clear", "accept"],
                                        },
                                    }}
                                    minDateTime={startDate ?? null}
                                    maxDateTime={
                                        startDate
                                            ? add(startDate, {
                                                  days: settings
                                                      ? settings.maximumReportingPeriodDays
                                                      : DEFAULT_SETTINGS.maximumReportingPeriodDays,
                                              })
                                            : null
                                    }
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                }}
                            >
                                <LoadingButton
                                    loading={isLoading}
                                    disabled={isLoading || isDisabled}
                                    variant="contained"
                                    color={
                                        isReportLoaded ? "warning" : "primary"
                                    }
                                    disableElevation
                                    onClick={handleReport}
                                    startIcon={<ModeOfTravelOutlined />}
                                >
                                    {isReportLoaded
                                        ? t("buttons.reset-report")
                                        : t("buttons.show-report")}
                                </LoadingButton>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider light />
                        </Grid>

                        <Grid item xs={12}>
                            <Stack
                                spacing={2}
                                direction="row"
                                alignItems="center"
                            >
                                <IconButton
                                    disabled={isLoading || maxInterval < 1}
                                    onClick={handleAutomaticInterval}
                                    color={
                                        isAutomaticInterval
                                            ? "error"
                                            : "success"
                                    }
                                >
                                    {isAutomaticInterval ? (
                                        <StopCircleOutlined />
                                    ) : (
                                        <PlayCircleOutlineOutlined />
                                    )}
                                </IconButton>

                                <Slider
                                    disabled={
                                        isLoading ||
                                        isAutomaticInterval ||
                                        maxInterval === 0
                                    }
                                    min={0}
                                    max={maxInterval}
                                    size="small"
                                    valueLabelDisplay="auto"
                                    valueLabelFormat={sliderLabel}
                                    value={currentInterval}
                                    onChange={(
                                        event: Event,
                                        newValue: number | number[]
                                    ) => {
                                        if (typeof newValue === "number") {
                                            setCurrentInterval(newValue);
                                        }
                                    }}
                                />
                            </Stack>
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        </ContentLayout>
    );
};

export default ReportForm;
