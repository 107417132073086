import { createSingletonHook } from "../helpers/singleton";
import ReactGA from "react-ga4";
import { User } from "../models/user";

export const [useAnalytics, AnalyticsProvider] = createSingletonHook(() => {
    const initGA = () => {
        if (
            process.env.REACT_APP_ENV !== "production" &&
            process.env.REACT_APP_GOOGLE_ANALYTICS_ID === ""
        )
            return;

        // @ts-ignore
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    };

    const sendPageView = (path: string, user: User | null) => {
        if (
            process.env.REACT_APP_ENV !== "production" &&
            process.env.REACT_APP_GOOGLE_ANALYTICS_ID === ""
        )
            return;

        if (user) ReactGA.set({ userId: user.id });

        ReactGA.send({ hitType: "pageview", page: path });
    };

    const sendModalView = (path: string, user: User | null) => {
        if (
            process.env.REACT_APP_ENV !== "production" &&
            process.env.REACT_APP_GOOGLE_ANALYTICS_ID === ""
        )
            return;

        if (user) ReactGA.set({ userId: user.id });

        ReactGA.send({ hitType: "pageview", page: path });
    };

    const sendEvent = (payload: any, user: User | null) => {
        if (
            process.env.REACT_APP_ENV !== "production" &&
            process.env.REACT_APP_GOOGLE_ANALYTICS_ID === ""
        )
            return;

        if (user) ReactGA.set({ userId: user.id });

        ReactGA.event(payload);
    };

    return {
        initGA,
        sendPageView,
        sendModalView,
        sendEvent,
    };
});
