import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { usePrefs } from "../services/prefs";
import {
    UserChangePassword,
    UserCreate,
    UserGet,
    UserUpdate,
    UserUploadImage,
} from "../models/user";
import {
    HeatMapGet,
    LocationCreate,
    LocationDelete,
    LocationGet,
    LocationUpdate,
} from "../models/location";
import {
    PoiCreate,
    PoiDelete,
    PoiGet,
    PoiImage,
    PoisGet,
    PoiUpdate,
} from "../models/poi";
import { ScreenGet, ScreenImage, ScreenUpdate } from "../models/screen";
import { SettingUpdate } from "../models/settings";

export const useApiPrivate = () => {
    const navigate = useNavigate();
    const prefs = usePrefs();

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    });

    api.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response && error.response.status === 401) {
                prefs.setToken(null);
                navigate("/login");
            }

            throw error;
        }
    );

    const source = axios.CancelToken.source();
    const cancel = () => source.cancel();
    const isCancel = (error: AxiosError) => axios.isCancel(error);

    const getCurrentUser = (token: string | null) => {
        return api.get("/account", {
            cancelToken: source.token,
            headers: {
                Authorization: `Bearer ${token ? token : prefs.token}`,
            },
        });
    };

    const updateCurrentUserPassword = (
        currentPassword: UserChangePassword["currentPassword"],
        newPassword: UserChangePassword["newPassword"]
    ) => {
        return api.post(
            "/account/change-password",
            {
                currentPassword,
                newPassword,
            },
            {
                cancelToken: source.token,
                headers: {
                    Authorization: `Bearer ${prefs.token}`,
                },
            }
        );
    };

    const uploadCurrentUserImage = (image: UserUploadImage["image"]) => {
        let formData = new FormData();

        formData.append("image", image);

        return api.post("/account/image", formData, {
            cancelToken: source.token,
            headers: {
                Authorization: `Bearer ${prefs.token}`,
                "Content-Type": "multipart/form-data",
            },
        });
    };

    const getUsers = () => {
        return api.get("/admin/users", {
            cancelToken: source.token,
            headers: {
                Authorization: `Bearer ${prefs.token}`,
            },
        });
    };

    const getUser = (id: UserGet["id"]) => {
        return api.get(`/admin/users/${id}`, {
            cancelToken: source.token,
            headers: {
                Authorization: `Bearer ${prefs.token}`,
            },
        });
    };

    const createUser = (
        firstName: UserCreate["firstName"],
        lastName: UserCreate["lastName"],
        email: UserCreate["email"],
        password: UserCreate["password"],
        authorities: UserCreate["authorities"],
        locationIds: UserCreate["locationIds"]
    ) => {
        return api.post(
            "/admin/users",
            {
                firstName,
                lastName,
                email,
                password,
                authorities,
                locationIds,
            },
            {
                cancelToken: source.token,
                headers: {
                    Authorization: `Bearer ${prefs.token}`,
                },
            }
        );
    };

    const updateUser = (
        id: UserUpdate["id"],
        firstName: UserUpdate["firstName"],
        lastName: UserUpdate["lastName"],
        email: UserUpdate["email"],
        imageUrl: UserUpdate["imageUrl"],
        authorities: UserUpdate["authorities"],
        locationIds: UserUpdate["locationIds"]
    ) => {
        return api.put(
            "/admin/users",
            {
                id,
                firstName,
                lastName,
                email,
                imageUrl,
                authorities,
                locationIds,
            },
            {
                cancelToken: source.token,
                headers: {
                    Authorization: `Bearer ${prefs.token}`,
                },
            }
        );
    };

    const deleteUser = (id: UserUpdate["id"]) => {
        return api.delete(`/admin/users/${id}`, {
            cancelToken: source.token,
            headers: {
                Authorization: `Bearer ${prefs.token}`,
            },
        });
    };

    const getLocations = () => {
        return api.get("/locations", {
            cancelToken: source.token,
            headers: {
                Authorization: `Bearer ${prefs.token}`,
            },
        });
    };

    const getLocation = (id: LocationGet["id"]) => {
        return api.get(`/locations/${id}`, {
            cancelToken: source.token,
            headers: {
                Authorization: `Bearer ${prefs.token}`,
            },
        });
    };

    const createLocation = (
        names: LocationCreate["names"],
        externalId: LocationCreate["externalId"],
        latitude: LocationCreate["latitude"],
        longitude: LocationCreate["longitude"],
        initialZoom: LocationCreate["initialZoom"]
    ) => {
        return api.post(
            "/locations",
            {
                names,
                externalId,
                latitude,
                longitude,
                initialZoom,
            },
            {
                cancelToken: source.token,
                headers: {
                    Authorization: `Bearer ${prefs.token}`,
                },
            }
        );
    };

    const updateLocation = (
        id: LocationUpdate["id"],
        names: LocationUpdate["names"],
        externalId: LocationUpdate["externalId"],
        latitude: LocationUpdate["latitude"],
        longitude: LocationUpdate["longitude"],
        initialZoom: LocationUpdate["initialZoom"]
    ) => {
        return api.put(
            `/locations/${id}`,
            {
                names,
                externalId,
                latitude,
                longitude,
                initialZoom,
            },
            {
                cancelToken: source.token,
                headers: {
                    Authorization: `Bearer ${prefs.token}`,
                },
            }
        );
    };

    const deleteLocation = (id: LocationDelete["id"]) => {
        return api.delete(`/locations/${id}`, {
            cancelToken: source.token,
            headers: {
                Authorization: `Bearer ${prefs.token}`,
            },
        });
    };

    const getPois = (id: PoisGet["id"]) => {
        return api.get(`/locations/${id}/pois`, {
            cancelToken: source.token,
            headers: {
                Authorization: `Bearer ${prefs.token}`,
            },
        });
    };

    const getPoi = (
        locationId: PoiGet["locationId"],
        poiId: PoiGet["poiId"]
    ) => {
        return api.get(`/locations/${locationId}/pois/${poiId}`, {
            cancelToken: source.token,
            headers: {
                Authorization: `Bearer ${prefs.token}`,
            },
        });
    };

    const createPoi = (
        locationId: PoiCreate["locationId"],
        latitude: PoiCreate["latitude"],
        longitude: PoiCreate["longitude"],
        poiDetails: PoiCreate["poiDetails"]
    ) => {
        return api.post(
            `/locations/${locationId}/pois`,
            {
                latitude,
                longitude,
                poiDetails,
            },
            {
                cancelToken: source.token,
                headers: {
                    Authorization: `Bearer ${prefs.token}`,
                },
            }
        );
    };

    const updatePoi = (
        locationId: PoiUpdate["locationId"],
        poiId: PoiUpdate["poiId"],
        latitude: PoiUpdate["latitude"],
        longitude: PoiUpdate["longitude"],
        imageUrl: PoiUpdate["imageUrl"],
        poiDetails: PoiUpdate["poiDetails"]
    ) => {
        return api.put(
            `/locations/${locationId}/pois/${poiId}`,
            {
                latitude,
                longitude,
                imageUrl,
                poiDetails,
            },
            {
                cancelToken: source.token,
                headers: {
                    Authorization: `Bearer ${prefs.token}`,
                },
            }
        );
    };

    const deletePoi = (
        locationId: PoiDelete["locationId"],
        poiId: PoiDelete["poiId"]
    ) => {
        return api.delete(`/locations/${locationId}/pois/${poiId}`, {
            cancelToken: source.token,
            headers: {
                Authorization: `Bearer ${prefs.token}`,
            },
        });
    };

    const uploadPoiImage = (
        locationId: PoiImage["locationId"],
        poiId: PoiImage["poiId"],
        image: PoiImage["image"]
    ) => {
        let formData = new FormData();

        formData.append("image", image);

        return api.post(
            `/locations/${locationId}/pois/${poiId}/image`,
            formData,
            {
                cancelToken: source.token,
                headers: {
                    Authorization: `Bearer ${prefs.token}`,
                    "Content-Type": "multipart/form-data",
                },
            }
        );
    };

    const getScreens = () => {
        return api.get("/landing-pages", {
            cancelToken: source.token,
            headers: {
                Authorization: `Bearer ${prefs.token}`,
            },
        });
    };

    const getScreen = (locationId: ScreenGet["locationId"]) => {
        return api.get(`/landing-pages/${locationId}`, {
            cancelToken: source.token,
            headers: {
                Authorization: `Bearer ${prefs.token}`,
            },
        });
    };

    const updateScreen = (
        locationId: ScreenUpdate["locationId"],
        token: ScreenUpdate["token"],
        landingPageDetails: ScreenUpdate["landingPageDetails"],
        poisSelected: ScreenUpdate["poisSelected"],
        mapEnabled: ScreenUpdate["mapEnabled"],
        mapHeatMapEnabled: ScreenUpdate["mapHeatMapEnabled"],
        highlightColor: ScreenUpdate["highlightColor"],
        coverPhotoUrl: ScreenUpdate["coverPhotoUrl"],
        officialLogoUrl: ScreenUpdate["officialLogoUrl"]
    ) => {
        return api.put(
            `/landing-pages/${locationId}`,
            {
                token,
                landingPageDetails,
                poisSelected,
                mapEnabled,
                mapHeatMapEnabled,
                highlightColor,
                coverPhotoUrl,
                officialLogoUrl,
            },
            {
                cancelToken: source.token,
                headers: {
                    Authorization: `Bearer ${prefs.token}`,
                },
            }
        );
    };

    const uploadScreenLogoImage = (
        locationId: ScreenImage["locationId"],
        image: ScreenImage["image"]
    ) => {
        let formData = new FormData();

        formData.append("image", image);

        return api.post(`/landing-pages/${locationId}/logo-image`, formData, {
            cancelToken: source.token,
            headers: {
                Authorization: `Bearer ${prefs.token}`,
                "Content-Type": "multipart/form-data",
            },
        });
    };

    const uploadScreenCoverImage = (
        locationId: ScreenImage["locationId"],
        image: ScreenImage["image"]
    ) => {
        let formData = new FormData();

        formData.append("image", image);

        return api.post(`/landing-pages/${locationId}/cover-image`, formData, {
            cancelToken: source.token,
            headers: {
                Authorization: `Bearer ${prefs.token}`,
                "Content-Type": "multipart/form-data",
            },
        });
    };

    const getHeatMap = (
        externalId: HeatMapGet["externalId"],
        dateFrom: HeatMapGet["dateFrom"],
        dateTo: HeatMapGet["dateTo"]
    ) => {
        return api.get(`/furia/${externalId}`, {
            cancelToken: source.token,
            headers: {
                Authorization: `Bearer ${prefs.token}`,
            },
            params: {
                dateFrom: dateFrom,
                dateTo: dateTo,
                fromDatabase:
                    process.env.REACT_APP_ENV !== "production" ? 1 : 0,
            },
        });
    };

    const getHeatMapReport = (
        externalId: HeatMapGet["externalId"],
        dateFrom: HeatMapGet["dateFrom"],
        dateTo: HeatMapGet["dateTo"],
        meters: HeatMapGet["meters"]
    ) => {
        return api.get(`/report/${externalId}/csv`, {
            cancelToken: source.token,
            headers: {
                Authorization: `Bearer ${prefs.token}`,
            },
            params: {
                dateFrom: dateFrom,
                dateTo: dateTo,
                meters: meters,
                fromDatabase:
                    process.env.REACT_APP_ENV !== "production" ? 1 : 0,
            },
        });
    };

    const getSettings = () => {
        return api.get("/settings", {
            cancelToken: source.token,
            headers: {
                Authorization: `Bearer ${prefs.token}`,
            },
        });
    };

    const updateSettings = (
        maximumReportingPeriodDays: SettingUpdate["maximumReportingPeriodDays"],
        groupDataTimingMinutes: SettingUpdate["groupDataTimingMinutes"],
        autoplaySpeedMillis: SettingUpdate["autoplaySpeedMillis"],
        perimeterForPoiMeters: SettingUpdate["perimeterForPoiMeters"]
    ) => {
        return api.put(
            "/settings",
            {
                maximumReportingPeriodDays,
                groupDataTimingMinutes,
                autoplaySpeedMillis,
                perimeterForPoiMeters,
            },
            {
                cancelToken: source.token,
                headers: {
                    Authorization: `Bearer ${prefs.token}`,
                },
            }
        );
    };

    return {
        cancel,
        isCancel,

        getCurrentUser,
        updateCurrentUserPassword,
        uploadCurrentUserImage,

        getUsers,
        getUser,
        createUser,
        updateUser,
        deleteUser,

        getLocations,
        getLocation,
        createLocation,
        updateLocation,
        deleteLocation,

        getPois,
        getPoi,
        createPoi,
        updatePoi,
        deletePoi,
        uploadPoiImage,

        getScreens,
        getScreen,
        updateScreen,
        uploadScreenLogoImage,
        uploadScreenCoverImage,

        getHeatMap,
        getHeatMapReport,

        getSettings,
        updateSettings,
    };
};
