import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createSingletonHook } from "../helpers/singleton";
import { usePrefs } from "./prefs";
import { useAnalytics } from "./analytics";
import { useApiAuth } from "../api/api-auth";
import { useApiPrivate } from "../api/api-private";
import { User, UserLogIn, UserRegister } from "../models/user";
import { Role } from "../models/role";

export const [useAuth, AuthProvider] = createSingletonHook(() => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const prefs = usePrefs();
    const analytics = useAnalytics();
    const apiAuth = useApiAuth();
    const apiPrivate = useApiPrivate();

    const [user, setUser] = useState<User | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLogInOut, setIsLogInOut] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isLocationAdmin, setIsLocationAdmin] = useState<boolean>(false);
    const [isUser, setIsUser] = useState<boolean>(false);

    useEffect(() => {
        if (pathname.includes("/welcome/") || !prefs.token) {
            setIsLoading(false);
            return;
        }

        getCurrentUser();

        return () => apiAuth.cancel();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logIn = (
        email: UserLogIn["email"],
        password: UserLogIn["password"],
        rememberMe: UserLogIn["rememberMe"],
        isRegister: boolean = false
    ) => {
        setIsError(false);
        setIsLogInOut(true);

        apiAuth
            .logIn(email, password, rememberMe)
            .then((response) => {
                console.log("logIn", response);

                setIsError(false);

                const token = response.data.id_token;
                prefs.setToken(token);

                getCurrentUser(true, isRegister, token);
            })
            .catch((error) => {
                console.error("logIn", error);

                setIsError(true);
                setIsLogInOut(false);
            });
    };

    const logOut = () => {
        setIsLoading(true);

        setUser(null);
        setIsAdmin(false);
        setIsLocationAdmin(false);
        setIsUser(false);

        prefs.setToken(null);

        navigate("/login");

        setIsLoading(false);

        analytics.sendEvent(
            {
                category: "user",
                action: "logout",
            },
            user
        );
    };

    const register = (
        key: UserRegister["key"],
        email: UserRegister["email"],
        password: UserRegister["password"],
        rememberMe: UserRegister["rememberMe"],
        isRegister: boolean = true
    ) => {
        setIsError(false);
        setIsLogInOut(true);

        apiAuth
            .register(key, password)
            .then((response) => {
                console.log("register", response);

                logIn(email, password, rememberMe, isRegister);

                if (!isRegister) {
                    analytics.sendEvent(
                        {
                            category: "user",
                            action: "password-reset",
                        },
                        user
                    );
                }
            })
            .catch((error) => {
                console.error("register", error);

                setIsError(true);
                setIsLogInOut(false);
            });
    };

    const getCurrentUser = (
        isLogin = false,
        isRegister = false,
        token: string | null = null
    ) => {
        apiPrivate
            .getCurrentUser(token)
            .then((response) => {
                console.log("getCurrentUser", response);

                const user = response.data as User;

                setUser(user);
                setRole(user.authorities);

                if (isRegister) {
                    analytics.sendEvent(
                        {
                            category: "user",
                            action: "register",
                        },
                        user
                    );
                }

                if (isLogin) {
                    navigate("/");

                    analytics.sendEvent(
                        {
                            category: "user",
                            action: "login",
                        },
                        user
                    );
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.error("getCurrentUser", error);

                setUser(null);

                if (isLogin) setIsLogInOut(false);
                else setIsLoading(false);
            });
    };

    const setRole = (roles: Role[]) => {
        if (roles.length === 0) {
            setIsUser(true);
            return;
        }

        const role = roles[0];

        if (role === "ROLE_ADMIN") {
            setIsAdmin(true);
        } else if (role === "ROLE_LOCATION_ADMIN") {
            setIsLocationAdmin(true);
        } else {
            setIsUser(true);
        }
    };

    return {
        user,
        isAdmin,
        isLocationAdmin,
        isUser,
        isLoading,
        isLogInOut,
        isError,
        getCurrentUser,
        setIsError,
        logIn,
        logOut,
        register,
    };
});
