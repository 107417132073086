import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import AuthLayout from "../../layouts/AuthLayout";
import { useAuth } from "../../services/auth";
import { isStrongPassword } from "../../helpers/utils";
import { User } from "../../models/user";

const Register = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const auth = useAuth();

    const [password, setPassword] = useState<User["password"]>("");
    const [repeatPassword, setRepeatPassword] = useState<User["password"]>("");
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showRepeatPassword, setShowRepeatPassword] =
        useState<boolean>(false);

    const query = new URLSearchParams(useLocation().search);
    const key = query.get("key");
    const email = query.get("email");

    const handleRegister = () => {
        if (auth.isLogInOut || !key || !email || !password) return;

        auth.register(key, email, password, rememberMe);
    };

    useEffect(() => {
        if (!key || !email) navigate("/login");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsError(
            password === "" || repeatPassword === ""
                ? false
                : password !== repeatPassword || !isStrongPassword(password)
        );

        setIsDisabled(
            password !== repeatPassword ||
                password === "" ||
                repeatPassword === "" ||
                !isStrongPassword(password)
        );
    }, [password, repeatPassword]);

    useEffect(() => {
        if (auth.user) navigate("/");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.user]);

    return (
        <AuthLayout>
            <Typography variant="h1">{t("titles.register")}</Typography>

            <Typography variant="caption">{t("captions.register")}</Typography>

            <Box
                sx={{
                    marginTop: 6,
                }}
            >
                <FormControl fullWidth margin="normal">
                    <TextField
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            setShowPassword((show) => !show)
                                        }
                                        edge="end"
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        label={t("inputs.password")}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <TextField
                        type={showRepeatPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            setShowRepeatPassword(
                                                (show) => !show
                                            )
                                        }
                                        edge="end"
                                    >
                                        {showRepeatPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        label={t("inputs.repeat-password")}
                        value={repeatPassword}
                        onChange={(event) =>
                            setRepeatPassword(event.target.value)
                        }
                        error={isError}
                        helperText={isError ? t("errors.password-rules") : " "}
                    />
                </FormControl>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <FormControlLabel
                        control={<Checkbox size="small" />}
                        label={t("buttons.remember-me")}
                        value={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                        disableTypography
                        sx={{
                            fontSize: "0.8rem",
                        }}
                    />

                    <LoadingButton
                        loading={auth.isLogInOut}
                        disabled={isDisabled}
                        variant="contained"
                        disableElevation
                        onClick={handleRegister}
                    >
                        {t("buttons.log-in")}
                    </LoadingButton>
                </Box>
            </Box>
        </AuthLayout>
    );
};

export default Register;
