import { ComponentType, createContext, createElement, useContext } from "react";

export const createSingletonHook = <Properties, Singleton>(
    useHook: (props: Properties) => Singleton
): [() => Singleton, ComponentType<Properties>] => {
    const Context = createContext<Singleton | undefined>(undefined);

    const SingletonHookProvider: ComponentType<Properties> = ({
        // @ts-ignore
        children,
        ...props
    }) => {
        const value = useHook(props as Properties);
        return createElement(Context.Provider, { value }, children);
    };

    const useSingletonHook = (): Singleton => {
        const value = useContext(Context);
        if (typeof value === "undefined") throw new Error();
        return value;
    };

    return [useSingletonHook, SingletonHookProvider];
};
