import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { useApiPrivate } from "../../api/api-private";
import { usePrefs } from "../../services/prefs";
import { useAlert } from "../../services/alert";
import AdminLayout from "../../layouts/AdminLayout";
import ContentLayout from "../../layouts/ContentLayout";
import Search from "../../components/blocks/Search";
import Table from "../../components/blocks/Table";
import { getLocationNameLocalized } from "../../helpers/utils";
import { User } from "../../models/user";
import { Role } from "../../models/role";

const Users = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const prefs = usePrefs();
    const alert = useAlert();
    const apiPrivate = useApiPrivate();

    const [users, setUsers] = useState<User[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const [search, setSearch] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [deleteId, setDeleteId] = useState<string>("");
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);

    const getUsers = () => {
        apiPrivate
            .getUsers()
            .then((response) => {
                console.log("getUsers", response);

                setUsers(response.data);
                setFilteredUsers(response.data);
            })
            .catch((error) => {
                console.error("getUsers", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onUserEdit = (userId: string) => {
        navigate(`/users/${userId}/edit`);
    };

    const onUserDelete = (userId: string) => {
        setIsDeleteOpen(true);
        setDeleteId(userId);
    };

    const onDeleteClose = () => {
        setIsDeleteOpen(false);
        setDeleteId("");
    };

    const onDeleteConfirm = () => {
        onDeleteClose();
        setIsLoading(true);

        apiPrivate
            .deleteUser(parseInt(deleteId))
            .then((response) => {
                console.log("deleteUser", response);

                getUsers();

                alert.show(t("messages.user-deleted"), "warning");
            })
            .catch((error) => {
                console.error("deleteUser", error);

                alert.show(t("errors.generic"), "error");

                setIsLoading(false);
            });
    };

    const userColumns = () => {
        return [
            t("columns.full-name"),
            t("columns.email"),
            t("columns.role"),
            t("columns.locations"),
            t("columns.created-at"),
        ];
    };

    const userRows = () => {
        return filteredUsers.map((user) => {
            return [
                user.id.toString(),
                `${user.firstName} ${user.lastName}`,
                user.email,
                userRoles(user.authorities),
                userLocations(user),
                new Date(user.createdDate).toLocaleDateString(),
            ];
        });
    };

    const userLocations = (user: User) => {
        return user.locations
            ? user.locations
                  .map((location) =>
                      getLocationNameLocalized(location, prefs.lang)
                  )
                  .join(", ")
            : "";
    };

    const userRoles = (authorities: Role[]) => {
        let roles = "";

        authorities.forEach((authority, index) => {
            if (index > 0) roles += ", ";

            if (authority === "ROLE_ADMIN") {
                roles += t("roles.admin");
            } else if (authority === "ROLE_LOCATION_ADMIN") {
                roles += t("roles.location-admin");
            } else {
                roles += t("roles.user");
            }
        });

        return roles;
    };

    useEffect(() => {
        setFilteredUsers(
            search !== ""
                ? users.filter(
                      (user) =>
                          user.firstName
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                          user.lastName
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                          user.email
                              .toLowerCase()
                              .includes(search.toLowerCase())
                  )
                : users
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        getUsers();

        return () => apiPrivate.cancel();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AdminLayout title={t("titles.users")}>
            <ContentLayout isLoading={isLoading} hasPadding={false}>
                <Search
                    search={search}
                    setSearch={setSearch}
                    buttonLink="/users/new"
                    buttonLabel={t("buttons.new-user") ?? ""}
                />

                <Table
                    type="users"
                    columns={userColumns()}
                    rows={userRows()}
                    onEdit={onUserEdit}
                    onDelete={onUserDelete}
                />
            </ContentLayout>

            <Dialog open={isDeleteOpen} onClose={onDeleteClose}>
                <DialogTitle>{t("titles.delete-user")}</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        {t("captions.delete-user")}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={onDeleteConfirm} color="error">
                        {t("buttons.confirm")}
                    </Button>
                </DialogActions>
            </Dialog>
        </AdminLayout>
    );
};

export default Users;
