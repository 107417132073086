import { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    Box,
    Breadcrumbs,
    Container,
    Grid,
    Link as MLink,
    Typography,
} from "@mui/material";
import { teal } from "@mui/material/colors";
import Logo from "../components/sidebar/Logo";
import Language from "../components/header/Language";
import Navigation from "../components/sidebar/Navigation";
import Footer from "../components/footer/Footer";
import Profile from "../components/header/Profile";

const AdminLayout = ({
    title,
    breadcrumbs,
    children,
}: {
    title: string;
    breadcrumbs?: {
        name: string;
        link: string;
    }[];
    children: ReactNode;
}) => {
    const { pathname } = useLocation();

    return (
        <Box
            sx={{
                minWidth: "1000px",
                background: teal[50],
            }}
        >
            <Container maxWidth="xl">
                <Grid container>
                    <Grid item xs={3}>
                        <Box
                            sx={{
                                position: "sticky",
                                top: 0,
                                paddingY: 4,
                            }}
                        >
                            <Logo />

                            <Navigation />
                        </Box>
                    </Grid>

                    <Grid item xs={9}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                minHeight: "100vh",
                            }}
                        >
                            <Box>
                                <Box
                                    sx={{
                                        position: "sticky",
                                        top: 0,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignContent: "center",
                                        paddingY: 3,
                                        background: teal[50],
                                        zIndex: 100,
                                    }}
                                >
                                    <Box>
                                        <Typography variant="h1">
                                            {title}
                                        </Typography>

                                        <Breadcrumbs separator="›">
                                            <MLink
                                                component={Link}
                                                to="/"
                                                underline="hover"
                                                color="inherit"
                                            >
                                                dRural
                                            </MLink>

                                            {breadcrumbs &&
                                                breadcrumbs.map(
                                                    (breadcrumb) => {
                                                        return breadcrumb.link !==
                                                            pathname ? (
                                                            <MLink
                                                                key={
                                                                    breadcrumb.name
                                                                }
                                                                component={Link}
                                                                to={
                                                                    breadcrumb.link
                                                                }
                                                                underline="hover"
                                                                color="inherit"
                                                            >
                                                                {
                                                                    breadcrumb.name
                                                                }
                                                            </MLink>
                                                        ) : (
                                                            <Typography
                                                                key={
                                                                    breadcrumb.name
                                                                }
                                                            >
                                                                {
                                                                    breadcrumb.name
                                                                }
                                                            </Typography>
                                                        );
                                                    }
                                                )}

                                            <Typography>{title}</Typography>
                                        </Breadcrumbs>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignContent: "center",
                                        }}
                                    >
                                        <Language />

                                        <Profile />
                                    </Box>
                                </Box>

                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    {children}
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    paddingY: 4,
                                }}
                            >
                                <Footer />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default AdminLayout;
