import { Fragment, ReactNode } from "react";
import {
    Box,
    CircularProgress,
    Divider,
    Paper,
    Typography,
} from "@mui/material";

const ContentLayout = ({
    title,
    caption,
    isLoading = false,
    hasPadding = true,
    hasMargin = false,
    children,
}: {
    title?: string;
    caption?: string;
    isLoading?: boolean;
    hasPadding?: boolean;
    hasMargin?: boolean;
    children?: ReactNode;
}) => {
    return (
        <Paper
            elevation={0}
            sx={{
                marginTop: hasMargin ? 2 : 0,
            }}
        >
            {(title || caption) && (
                <Fragment>
                    <Box
                        sx={{
                            padding: 2,
                        }}
                    >
                        {title && <Typography variant="h2">{title}</Typography>}

                        {caption && (
                            <Typography variant="caption">{caption}</Typography>
                        )}
                    </Box>

                    <Divider />
                </Fragment>
            )}

            <Box
                sx={{
                    padding: hasPadding ? 2 : 0,
                }}
            >
                {isLoading ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: "100px",
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    children
                )}
            </Box>
        </Paper>
    );
};

export default ContentLayout;
