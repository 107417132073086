import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, FormControl, Grid, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { SaveOutlined } from "@mui/icons-material";
import { useAlert } from "../../services/alert";
import { useApiPrivate } from "../../api/api-private";
import ContentLayout from "../../layouts/ContentLayout";
import Translations from "../elements/Translations";
import Map from "../maps/Map";
import {
    DEFAULT_LATITUDE,
    DEFAULT_LONGITUDE,
    DEFAULT_ZOOM,
} from "../../helpers/constants";
import { Location, LocationName } from "../../models/location";

const LocationForm = ({
    type,
    location,
}: {
    type: "new" | "edit";
    location?: Location;
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const alert = useAlert();
    const apiPrivate = useApiPrivate();

    const [tab, setTab] = useState<number>(0);
    const [nameEn, setNameEn] = useState<LocationName["name"]>(
        location && location.names
            ? location.names.find((name) => name.language === "ENGLISH")
                  ?.name ?? ""
            : ""
    );
    const [nameHr, setNameHr] = useState<LocationName["name"]>(
        location && location.names
            ? location.names.find((name) => name.language === "CROATIAN")
                  ?.name ?? ""
            : ""
    );
    const [externalId, setExternalId] = useState<Location["externalId"]>(
        location ? location.externalId : ""
    );
    const [latitude, setLatitude] = useState<Location["latitude"]>(
        location ? location.latitude : DEFAULT_LATITUDE
    );
    const [longitude, setLongitude] = useState<Location["longitude"]>(
        location ? location.longitude : DEFAULT_LONGITUDE
    );
    const [zoom, setZoom] = useState<Location["initialZoom"]>(
        location ? location.initialZoom : DEFAULT_ZOOM
    );

    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleLocationSave = () => {
        setIsLoading(true);

        if (type === "new") {
            createLocation();
        } else if (type === "edit") {
            updateLocation();
        }
    };

    const createLocation = () => {
        apiPrivate
            .createLocation(mapNames(), externalId, latitude, longitude, zoom)
            .then((response) => {
                console.log("createLocation", response);

                navigate("/locations");

                alert.show(t("messages.location-created"), "success");
            })
            .catch((error) => {
                console.error("createLocation", error);

                alert.show(t("errors.generic"), "error", 5000);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateLocation = () => {
        if (!location) return;

        apiPrivate
            .updateLocation(
                location.id,
                mapNames(),
                externalId,
                latitude,
                longitude,
                zoom
            )
            .then((response) => {
                console.log("updateLocation", response);

                navigate("/locations");

                alert.show(t("messages.location-updated"), "success");
            })
            .catch((error) => {
                console.error("updateLocation", error);

                alert.show(t("errors.generic"), "error", 5000);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const mapNames = () => {
        return [
            {
                language: "CROATIAN",
                name: nameHr,
            },
            {
                language: "ENGLISH",
                name: nameEn,
            },
        ] as LocationName[];
    };

    useEffect(() => {
        setIsDisabled(nameEn === "" || nameHr === "" || externalId === "");
    }, [nameEn, nameHr, externalId]);

    return (
        <Fragment>
            <ContentLayout
                title={t("titles.name") ?? ""}
                caption={t("captions.required-field") ?? ""}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Translations tab={tab} setTab={setTab} />

                        <FormControl fullWidth margin="dense">
                            <TextField
                                required
                                label={t("inputs.name")}
                                value={tab === 0 ? nameEn : nameHr}
                                onChange={(event) =>
                                    tab === 0
                                        ? setNameEn(event.target.value)
                                        : setNameHr(event.target.value)
                                }
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </ContentLayout>

            <ContentLayout
                title={t("titles.details") ?? ""}
                caption={t("captions.required-field") ?? ""}
                hasMargin={true}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth margin="dense">
                            <TextField
                                required
                                label={t("inputs.external-id")}
                                value={externalId}
                                onChange={(event) =>
                                    setExternalId(event.target.value)
                                }
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </ContentLayout>

            <ContentLayout
                title={t("titles.map") ?? ""}
                caption={t("captions.drag-and-zoom-location") ?? ""}
                hasMargin={true}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Map
                            zoom={zoom}
                            setZoom={setZoom}
                            markers={[
                                {
                                    id: 0,
                                    latitude: latitude,
                                    longitude: longitude,
                                    imageUrl: null,
                                    ctime: new Date().toLocaleDateString(),
                                    poidetails: null,
                                },
                            ]}
                            setMarkers={(markers) => {
                                setLatitude(markers[0].latitude);
                                setLongitude(markers[0].longitude);
                            }}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl fullWidth margin="dense">
                            <TextField
                                disabled
                                label={t("inputs.latitude")}
                                type="number"
                                inputProps={{
                                    step: "0.000001",
                                }}
                                value={latitude}
                                onChange={(event) =>
                                    setLatitude(parseFloat(event.target.value))
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl fullWidth margin="dense">
                            <TextField
                                disabled
                                label={t("inputs.longitude")}
                                type="number"
                                inputProps={{
                                    step: "0.000001",
                                }}
                                value={longitude}
                                onChange={(event) =>
                                    setLongitude(parseFloat(event.target.value))
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl fullWidth margin="dense">
                            <TextField
                                disabled
                                type="number"
                                label={t("inputs.zoom")}
                                value={zoom}
                                onChange={(event) => {
                                    const newZoom = parseInt(
                                        event.target.value
                                    );

                                    if (newZoom < 0 || newZoom > 22) return;

                                    setZoom(newZoom);
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </ContentLayout>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 4,
                }}
            >
                <LoadingButton
                    loading={isLoading}
                    disabled={isDisabled}
                    variant="contained"
                    disableElevation
                    onClick={handleLocationSave}
                    startIcon={<SaveOutlined />}
                >
                    {t("buttons.save")}
                </LoadingButton>
            </Box>
        </Fragment>
    );
};

export default LocationForm;
