import { useTranslation } from "react-i18next";
import { useAuth } from "../../services/auth";
import AdminLayout from "../../layouts/AdminLayout";
import ContentLayout from "../../layouts/ContentLayout";
import UserForm from "../../components/forms/UserForm";

const Account = () => {
    const { t } = useTranslation();
    const auth = useAuth();

    return (
        <AdminLayout title={t("titles.account")}>
            {auth.user ? (
                <UserForm type="account" user={auth.user} />
            ) : (
                <ContentLayout isLoading={true} />
            )}
        </AdminLayout>
    );
};

export default Account;
