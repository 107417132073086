import { forwardRef, SyntheticEvent } from "react";
import { useNavigate } from "react-router-dom";
import {
    Alert as MuiAlert,
    AlertProps,
    Button,
    Slide,
    SlideProps,
    Snackbar,
} from "@mui/material";
import { useAlert } from "../../services/alert";

const AlertModal = forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
    return <MuiAlert ref={ref} {...props} />;
});

export default function Alert() {
    const navigate = useNavigate();
    const alert = useAlert();

    const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") return;

        alert.hide();
    };

    return (
        <Snackbar
            open={alert.isOpened}
            autoHideDuration={alert.duration}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            TransitionComponent={(props: SlideProps) => {
                return <Slide {...props} direction="up" />;
            }}
        >
            <AlertModal
                onClose={handleClose}
                severity={alert.type}
                action={
                    alert.actionLink && alert.actionLabel ? (
                        <Button
                            color="inherit"
                            size="small"
                            onClick={() => {
                                if (alert.actionLink)
                                    navigate(alert.actionLink);

                                alert.hide();
                            }}
                        >
                            {alert.actionLabel}
                        </Button>
                    ) : undefined
                }
                elevation={1}
            >
                {alert.message}
            </AlertModal>
        </Snackbar>
    );
}
