import { Route } from "../models/route";
import Register from "../pages/auth/Register";
import LogIn from "../pages/auth/LogIn";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";

export const authRoutes: Route[] = [
    {
        path: "/register",
        element: Register,
        roles: [],
    },
    {
        path: "/login",
        element: LogIn,
        roles: [],
    },
    {
        path: "/forgot-password",
        element: ForgotPassword,
        roles: [],
    },
    {
        path: "/reset-password",
        element: ResetPassword,
        roles: [],
    },
];
