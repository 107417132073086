import axios, { AxiosError } from "axios";
import { WelcomeGet } from "../models/screen";
import { HeatMapGet } from "../models/location";

export const useApiPublic = () => {
    const api = axios.create({
        headers: {
            "Content-Type": "application/json",
        },
    });

    const source = axios.CancelToken.source();
    const cancel = () => source.cancel();
    const isCancel = (error: AxiosError) => axios.isCancel(error);

    const getScreen = (token: WelcomeGet["token"]) => {
        return api.get(
            `${process.env.REACT_APP_API_URL}/landing-pages/token/${token}`,
            {
                cancelToken: source.token,
            }
        );
    };

    const getHeatMap = (externalId: HeatMapGet["externalId"]) => {
        return api.get(`${process.env.REACT_APP_FURIA_API_URL}/${externalId}`, {
            cancelToken: source.token,
            params: {
                fromDatabase:
                    process.env.REACT_APP_ENV !== "production" ? 1 : 0,
            },
        });
    };

    return {
        cancel,
        isCancel,

        getScreen,
        getHeatMap,
    };
};
