import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import _ from "lodash";
import { format, subMinutes } from "date-fns";
import { MuiColorInput } from "mui-color-input";
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardActionArea,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    Skeleton,
    Switch,
    TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { SaveOutlined } from "@mui/icons-material";
import { usePrefs } from "../../services/prefs";
import { useAlert } from "../../services/alert";
import { useApiPrivate } from "../../api/api-private";
import {
    getMapMarkers,
    getMapZoom,
    getPoiNameLocalized,
} from "../../helpers/utils";
import ContentLayout from "../../layouts/ContentLayout";
import Translations from "../elements/Translations";
import Map from "../maps/Map";
import { FURIA_DATE_FORMAT } from "../../helpers/constants";
import { HeatMap } from "../../models/location";
import { Poi } from "../../models/poi";
import { Screen, ScreenDetails } from "../../models/screen";

const ScreenForm = ({ screen }: { screen: Screen }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const prefs = usePrefs();
    const alert = useAlert();
    const apiPrivate = useApiPrivate();

    const [tab, setTab] = useState<number>(0);
    const [titleEn, setTitleEn] = useState<ScreenDetails["title"]>(
        screen.landingPageDetails.find(
            (detail) => detail.language === "ENGLISH"
        )?.title ?? ""
    );
    const [titleHr, setTitleHr] = useState<ScreenDetails["title"]>(
        screen.landingPageDetails.find(
            (detail) => detail.language === "CROATIAN"
        )?.title ?? ""
    );
    const [descriptionEn, setDescriptionEn] = useState<
        ScreenDetails["shortDescription"]
    >(
        screen.landingPageDetails.find(
            (detail) => detail.language === "ENGLISH"
        )?.shortDescription ?? ""
    );
    const [descriptionHr, setDescriptionHr] = useState<
        ScreenDetails["shortDescription"]
    >(
        screen.landingPageDetails.find(
            (detail) => detail.language === "CROATIAN"
        )?.shortDescription ?? ""
    );
    const [termsEn, setTermsEn] = useState<ScreenDetails["termsOfUse"]>(
        screen.landingPageDetails.find(
            (detail) => detail.language === "ENGLISH"
        )?.termsOfUse ?? ""
    );
    const [termsHr, setTermsHr] = useState<ScreenDetails["termsOfUse"]>(
        screen.landingPageDetails.find(
            (detail) => detail.language === "CROATIAN"
        )?.termsOfUse ?? ""
    );
    const [cookiesEn, setCookiesEn] = useState<ScreenDetails["gdprTermsOfUse"]>(
        screen.landingPageDetails.find(
            (detail) => detail.language === "ENGLISH"
        )?.gdprTermsOfUse ?? ""
    );
    const [cookiesHr, setCookiesHr] = useState<ScreenDetails["gdprTermsOfUse"]>(
        screen.landingPageDetails.find(
            (detail) => detail.language === "CROATIAN"
        )?.gdprTermsOfUse ?? ""
    );
    const [color, setColor] = useState<string>(screen.highlightColor ?? "#000");
    const [coverImageUrl] = useState<Screen["coverPhotoUrl"]>(
        screen.coverPhotoUrl ?? ""
    );
    const [logoImageUrl] = useState<Screen["officialLogoUrl"]>(
        screen.officialLogoUrl ?? ""
    );
    const [pois, setPois] = useState<Poi[]>(screen.poisSelected ?? []);
    const [hasMap, setHasMap] = useState<boolean>(screen.mapEnabled);
    const [hasHeatMap, setHasHeatMap] = useState<boolean>(
        screen.mapHeatMapEnabled
    );

    const [newCoverImage, setNewCoverImage] = useState<File | null>(null);
    const [newLogoImage, setNewLogoImage] = useState<File | null>(null);

    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [groupedHeatMapData, setGroupedHeatMapData] = useState<HeatMap[][]>(
        []
    );

    const handleScreenSave = () => {
        setIsLoading(true);

        apiPrivate
            .updateScreen(
                screen.location.id,
                screen.token,
                mapScreenDetails(),
                pois.map((poi) => poi.id),
                hasMap,
                hasHeatMap,
                color,
                coverImageUrl,
                logoImageUrl
            )
            .then((response) => {
                console.log("updateScreen", response);

                handleCoverImage();
            })
            .catch((error) => {
                console.error("updateScreen", error);

                alert.show(t("errors.generic"), "error", 5000);

                setIsLoading(false);
            });
    };

    const handleCoverImage = () => {
        if (!newCoverImage) {
            handleLogoImage();
            return;
        }

        apiPrivate
            .uploadScreenCoverImage(screen.location.id, newCoverImage)
            .then((response) => {
                console.log("uploadScreenCoverImage", response);

                handleLogoImage();
            })
            .catch((error) => {
                console.error("uploadScreenCoverImage", error);

                alert.show(t("errors.generic"), "error", 5000);

                setIsLoading(false);
            });
    };

    const handleLogoImage = () => {
        if (!newLogoImage) {
            navigate("/screens");

            alert.show(t("messages.screen-updated"), "success");

            setIsLoading(false);

            return;
        }

        apiPrivate
            .uploadScreenLogoImage(screen.location.id, newLogoImage)
            .then((response) => {
                console.log("uploadScreenCoverImage", response);

                navigate("/screens");

                alert.show(t("messages.screen-updated"), "success");
            })
            .catch((error) => {
                console.error("uploadScreenCoverImage", error);

                alert.show(t("errors.generic"), "error", 5000);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const mapScreenDetails = () => {
        return [
            {
                language: "CROATIAN",
                shortDescription: descriptionHr,
                termsOfUse: termsHr,
                gdprTermsOfUse: cookiesHr,
                title: titleHr,
            },
            {
                language: "ENGLISH",
                shortDescription: descriptionEn,
                termsOfUse: termsEn,
                gdprTermsOfUse: cookiesEn,
                title: titleEn,
            },
        ] as ScreenDetails[];
    };

    const getReport = () => {
        if (!screen.location) return;

        apiPrivate
            .getHeatMap(
                screen.location.externalId,
                format(subMinutes(new Date(), 30), FURIA_DATE_FORMAT),
                format(new Date(), FURIA_DATE_FORMAT)
            )
            .then((response) => {
                console.log("getHeatMap", response);

                const heatMapData = response.data as HeatMap[];

                if (heatMapData && heatMapData.length > 0) {
                    let intervalPoints: HeatMap[][];

                    intervalPoints = _.chain(heatMapData)
                        .groupBy()
                        .sortBy((a, b) => b)
                        .value();

                    console.log("groupHeatMapData", intervalPoints);

                    if (intervalPoints.length > 0) {
                        setGroupedHeatMapData(intervalPoints);
                    }
                }
            })
            .catch((error) => {
                console.error("getHeatMap", error);

                setGroupedHeatMapData([]);
                alert.show(t("errors.generic"), "error");
            });
    };

    useEffect(() => {
        setIsDisabled(titleEn === "" || titleHr === "");
    }, [titleEn, titleHr]);

    useEffect(() => {
        if (hasHeatMap) getReport();
        else setGroupedHeatMapData([]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasHeatMap]);

    return (
        <Fragment>
            {screen.highlightColor && (
                <ContentLayout>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs>
                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    disabled
                                    label={t("inputs.url")}
                                    value={`${process.env.REACT_APP_URL}/welcome/${screen.token}`}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <Box
                                sx={{
                                    marginLeft: 2,
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        navigator.clipboard
                                            .writeText(
                                                `${process.env.REACT_APP_URL}/welcome/${screen.token}`
                                            )
                                            .then(() => {
                                                alert.show(
                                                    t("messages.copied"),
                                                    "success"
                                                );
                                            });
                                    }}
                                >
                                    {t("buttons.copy-url")}
                                </Button>

                                <Button
                                    variant="contained"
                                    disableElevation
                                    onClick={() => {
                                        window.open(
                                            `${process.env.REACT_APP_URL}/welcome/${screen.token}`,
                                            "_blank"
                                        );
                                    }}
                                    sx={{
                                        marginLeft: 2,
                                    }}
                                >
                                    {t("buttons.preview")}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </ContentLayout>
            )}

            <ContentLayout
                title={t("titles.content") ?? ""}
                caption={t("captions.required-field") ?? ""}
                hasMargin={!!screen.highlightColor}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Translations tab={tab} setTab={setTab} />

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="dense">
                                    <TextField
                                        required
                                        label={t("inputs.title")}
                                        value={tab === 0 ? titleEn : titleHr}
                                        onChange={(event) =>
                                            tab === 0
                                                ? setTitleEn(event.target.value)
                                                : setTitleHr(event.target.value)
                                        }
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth margin="dense">
                                    <TextField
                                        required
                                        multiline
                                        minRows={3}
                                        maxRows={Infinity}
                                        label={t("inputs.short-description")}
                                        value={
                                            tab === 0
                                                ? descriptionEn
                                                : descriptionHr
                                        }
                                        onChange={(event) =>
                                            tab === 0
                                                ? setDescriptionEn(
                                                      event.target.value
                                                  )
                                                : setDescriptionHr(
                                                      event.target.value
                                                  )
                                        }
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider light />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth margin="dense">
                                    <TextField
                                        required
                                        multiline
                                        minRows={3}
                                        maxRows={10}
                                        label={t("inputs.terms")}
                                        value={tab === 0 ? termsEn : termsHr}
                                        onChange={(event) =>
                                            tab === 0
                                                ? setTermsEn(event.target.value)
                                                : setTermsHr(event.target.value)
                                        }
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth margin="dense">
                                    <TextField
                                        required
                                        multiline
                                        minRows={3}
                                        maxRows={10}
                                        label={t("inputs.cookies")}
                                        value={
                                            tab === 0 ? cookiesEn : cookiesHr
                                        }
                                        onChange={(event) =>
                                            tab === 0
                                                ? setCookiesEn(
                                                      event.target.value
                                                  )
                                                : setCookiesHr(
                                                      event.target.value
                                                  )
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ContentLayout>

            <ContentLayout
                title={t("titles.branding") ?? ""}
                caption={t("captions.required-field") ?? ""}
                hasMargin={true}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MuiColorInput
                            required
                            fullWidth
                            label={t("inputs.color")}
                            value={color}
                            onChange={(newColor: string) => setColor(newColor)}
                            format="hex"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <FormLabel
                                required
                                sx={{
                                    marginBottom: 0.5,
                                }}
                            >
                                {t("inputs.cover-image")}
                            </FormLabel>

                            <Dropzone
                                maxFiles={1}
                                maxSize={50000000}
                                onDrop={(files) => setNewCoverImage(files[0])}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <Card
                                        variant="outlined"
                                        {...getRootProps()}
                                    >
                                        <CardActionArea>
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    height: "140px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    padding: 2,
                                                }}
                                            >
                                                {newCoverImage ? (
                                                    <img
                                                        src={URL.createObjectURL(
                                                            newCoverImage
                                                        )}
                                                        width="100%"
                                                        height="100%"
                                                        alt="cover"
                                                        style={{
                                                            objectFit:
                                                                "contain",
                                                        }}
                                                    />
                                                ) : coverImageUrl ? (
                                                    <img
                                                        src={coverImageUrl}
                                                        width="100%"
                                                        height="100%"
                                                        alt="cover"
                                                        style={{
                                                            objectFit:
                                                                "contain",
                                                        }}
                                                    />
                                                ) : (
                                                    <Skeleton
                                                        variant="rectangular"
                                                        width={200}
                                                        height={100}
                                                    />
                                                )}

                                                <input
                                                    {...getInputProps({
                                                        accept: "image/*",
                                                    })}
                                                />
                                            </Box>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Dropzone>

                            <FormHelperText
                                sx={{
                                    marginX: 0,
                                }}
                            >
                                {t("inputs.upload")}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <FormLabel
                                sx={{
                                    marginBottom: 0.5,
                                }}
                            >
                                {t("inputs.logo-image")}
                            </FormLabel>

                            <Dropzone
                                maxFiles={1}
                                maxSize={50000000}
                                onDrop={(files) => setNewLogoImage(files[0])}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <Card
                                        variant="outlined"
                                        {...getRootProps()}
                                    >
                                        <CardActionArea>
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    height: "140px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    padding: 2,
                                                }}
                                            >
                                                {newLogoImage ? (
                                                    <img
                                                        src={URL.createObjectURL(
                                                            newLogoImage
                                                        )}
                                                        width="100%"
                                                        height="100%"
                                                        alt="logo"
                                                        style={{
                                                            objectFit:
                                                                "contain",
                                                        }}
                                                    />
                                                ) : logoImageUrl ? (
                                                    <img
                                                        src={logoImageUrl}
                                                        width="100%"
                                                        height="100%"
                                                        alt="logo"
                                                        style={{
                                                            objectFit:
                                                                "contain",
                                                        }}
                                                    />
                                                ) : (
                                                    <Skeleton
                                                        variant="rectangular"
                                                        width={80}
                                                        height={100}
                                                    />
                                                )}

                                                <input
                                                    {...getInputProps({
                                                        accept: "image/*",
                                                    })}
                                                />
                                            </Box>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Dropzone>

                            <FormHelperText
                                sx={{
                                    marginX: 0,
                                }}
                            >
                                {t("inputs.upload")}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            </ContentLayout>

            <ContentLayout title={t("titles.places") ?? ""} hasMargin={true}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            loading={false}
                            options={
                                screen && screen.location.pois
                                    ? screen.location.pois
                                    : []
                            }
                            value={pois}
                            onChange={(event, newPoi) => setPois(newPoi)}
                            getOptionLabel={(poi) =>
                                getPoiNameLocalized(poi, prefs.lang)
                            }
                            filterSelectedOptions
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("inputs.pois")}
                                    margin="dense"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={hasMap}
                                    onChange={(event) => {
                                        const isChecked = event.target.checked;

                                        setHasMap(isChecked);

                                        if (!isChecked) setHasHeatMap(false);
                                    }}
                                />
                            }
                            label={t("inputs.show-map")}
                        />

                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={!hasMap}
                                    checked={hasHeatMap}
                                    onChange={(event) =>
                                        setHasHeatMap(event.target.checked)
                                    }
                                />
                            }
                            label={t("inputs.show-heat-map")}
                            sx={{
                                marginLeft: 1,
                            }}
                        />
                    </Grid>

                    {hasMap && (
                        <Grid item xs={12}>
                            {hasHeatMap ? (
                                <Map
                                    zoom={getMapZoom(screen.location)}
                                    markers={getMapMarkers(screen.location)}
                                    points={groupedHeatMapData}
                                    interval={0}
                                />
                            ) : (
                                <Map
                                    zoom={getMapZoom(screen.location)}
                                    markers={getMapMarkers(screen.location)}
                                />
                            )}
                        </Grid>
                    )}
                </Grid>
            </ContentLayout>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 4,
                }}
            >
                <LoadingButton
                    loading={isLoading}
                    disabled={isDisabled}
                    variant="contained"
                    disableElevation
                    onClick={handleScreenSave}
                    startIcon={<SaveOutlined />}
                >
                    {t("buttons.save")}
                </LoadingButton>
            </Box>
        </Fragment>
    );
};

export default ScreenForm;
