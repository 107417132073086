import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useApiPrivate } from "../../api/api-private";
import AdminLayout from "../../layouts/AdminLayout";
import ContentLayout from "../../layouts/ContentLayout";
import UserForm from "../../components/forms/UserForm";
import { User } from "../../models/user";

const EditUser = () => {
    const { t } = useTranslation();
    const { userId } = useParams();
    const apiPrivate = useApiPrivate();

    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        if (!userId) return;

        apiPrivate
            .getUser(parseInt(userId))
            .then((response) => {
                console.log("getUser", response);

                setUser(response.data);
            })
            .catch((error) => {
                console.error("getUser", error);
            });

        return () => apiPrivate.cancel();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    return (
        <AdminLayout
            title={t("titles.edit-user")}
            breadcrumbs={[
                {
                    name: t("titles.users"),
                    link: "/users",
                },
            ]}
        >
            {user ? (
                <UserForm type="edit" user={user} />
            ) : (
                <ContentLayout isLoading={true} />
            )}
        </AdminLayout>
    );
};

export default EditUser;
