import { useTranslation } from "react-i18next";
import AdminLayout from "../../layouts/AdminLayout";
import LocationForm from "../../components/forms/LocationForm";

const NewLocation = () => {
    const { t } = useTranslation();

    return (
        <AdminLayout
            title={t("titles.new-location")}
            breadcrumbs={[
                {
                    name: t("titles.locations"),
                    link: "/locations",
                },
            ]}
        >
            <LocationForm type="new" />
        </AdminLayout>
    );
};

export default NewLocation;
